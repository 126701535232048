.heading {
    color: var(--black);
    font-weight: 600;
    font-size: var(--FONT16);
    text-transform: uppercase;
    padding-bottom: 24px;
}

.pink-btn {
    background-color: var(--pink);
    border-color: transparent !important;
    color: var(--white) !important;
    border-radius: 4px !important;
    font-size: var(--FONT14);
    font-weight: 600;
    padding: 6px 16px 6px 16px;
}


.tripBtn {
    border: 1px solid black;
    text-align: center;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    color: #000;
    font-size: 14px;
    cursor: pointer;
}

.Button-disable {
    background-color: transparent;
    border-color: transparent !important;
    color: rgba(0, 0, 0, 0.60) !important;
    border-radius: 4px !important;
    font-size: var(--FONT14);
    font-weight: 600;
    padding: 8px 16px 8px 16px;
}



.form-label {
    margin-bottom: 0px;
}

.requiredStyle {
    margin-bottom: 0px;
    color: var(--Warning);
}

.mainDiv {
    display: flex;
    gap: 10px;
}

.main-content .mainCard {
    border-radius: 4px;
    border: 1px solid #e9ebec;
    background-color: var(--white) !important;
}

.main-content .mainCard .main_card_body {
    padding: 15px;
}

.main-content .mainCard .main_card_body .row.my-2 {
    margin: 0px !important;
}

.main-content .mainCard .main_card_body .row .mb-2 {
    margin-bottom: 0px !important;
}

.main-content .mainCard .main_card_body .row .col-sm-auto input.form-control::placeholder {
    font-size: var(--FONT12);
    font-weight: 400;
    color: var(--gray);
}

.main-content .mainCard .main_card_body .row .btn-transparent {
    border: 0px;
    position: absolute;
    right: 0;
    min-height: 36px;
    max-height: 36px;
    padding: 0px 10px;
}

.main-content .mainCard .main_card_body .row .btn-transparent:before {
    content: "\f0156";
    font: normal normal normal 24px/1 Material Design Icons;
    font-size: 20px;
    color: var(--gray);
    min-height: 36px;
    max-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-content .mainCard .main_card_body .row .btn-transparent svg {
    opacity: 0;
    display: none;
}

.main-content .mainCard .main_card_body .row .btn-transparent:disabled {
    opacity: 0;
}

.main-content .mainCard .main_card_body .Card-Button {
    position: absolute;
    top: 34px;
    right: 34px;
}

.mainCard .mainCard .table-responsive {
    padding-top: 16px;
    border-top: 1px solid var(--gray-100);
    overflow-y: scroll;
    margin-bottom: 30px;
}

.main-content .mainCard .main_card_body .table-responsive .table {
    margin-bottom: 0px;
}

.main-content .mainCard .main_card_body .table-responsive tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 1fr 1fr 140px;
    ;
}

.main-content .mainCard .main_card_body .table-responsive .table .table-body-scroll {
    display: table-caption;
    min-height: 280px;
}

.main-content .mainCard .main_card_body .table-responsive .table tr th {
    height: auto !important;
    background-color: var(--black);
    color: var(--white);
    font-size: var(--FONT14);
    /* padding-bottom: 13px;
    padding-top: 13px; */
    font-weight: 600;
}



.main-content .mainCard .main_card_body .table-responsive .table tr th div .th-count {
    padding: 4px 10px 4px 10px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 4px;
    background-color: var(--yellow);
    margin-left: 10px;
    color: var(--black);
}

.main-content .mainCard .main_card_body .table-responsive .table tr:nth-child(2) th {
    background-color: var(--white) !important;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td p {
    font-size: var(--FONT14);
    font-weight: 400;
    margin-bottom: 0px;
    position: relative;
    line-height: 20px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .td-wrapper {
    display: flex;
    align-items: flex-start !important;
    position: relative;
    line-height: 20px;
    flex-direction: column;
}


.main-content .mainCard .main_card_body .table-responsive .table tr:nth-child(2) th {
    background-color: var(--white) !important;
}


.main-content .mainCard .main_card_body .table-responsive .table tbody tr td {
    background-color: var(--white);
    padding: 10px;
    border-bottom: 1px solid #f4f4f7;
    font-size: var(--FONT14);
    font-weight: 400;
    margin-bottom: 0px;
    color: var(--black-dark);
    display: flex;
    align-items: center;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td p {
    font-size: var(--FONT14);
    font-weight: 400;
    margin-bottom: 0px;
    position: relative;
    line-height: 20px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .td-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    line-height: 20px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .td-wrapper i {
    font-size: var(--FONT20);
    line-height: 0;
    color: var(--dark-gray);
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    position: relative;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .form-switch {
    padding-left: 0px;
    margin-bottom: 0px;
}

.form-check-input:focus {
    border: none !important;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .form-switch .form-check-input:checked {
    background-color: var(--green) !important;
    filter: opacity(1);
    border: none;
}
.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .form-switch .form-check-input:focus{

    box-shadow: none;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .form-switch .form-check-input {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 20px;
    background-color: var(--white);
    border-color: var(--gray);
    filter: opacity(0.5);
    cursor: pointer;
}


.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .Edit-Buttton {
    background: transparent;
    border: 0px;
    padding: 0px;
    line-height: 0px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .Edit-Buttton i:before {
    content: "\f0cb6";
    font: normal normal normal 24px/1 Material Design Icons;
    color: var(--skyBlue);
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .Delete-Buttton {
    background: transparent;
    border: 0px;
    color: var(--red);
    padding: 0px;
    line-height: 0px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper .Delete-Buttton i:before {
    font-size: var(--FONT24);
    color: var(--Warning);
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td.disableTd {
    background-color: #f4f4f7 !important;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td.disableTd p {
    color: #b8bdd0;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td.disableTd .td-wrapper {
    color: #b8bdd0;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr td.disableTd .td-wrapper i {
    color: #b8bdd0;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty {
    border-bottom: 0px !important;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty .noData {
    padding: 10px 0px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty .noData .no-data-Icon {
    display: grid;
    width: fit-content;
    margin: auto;
    text-align: center;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty .noData .no-data-Icon .Icon {
    max-width: 144px;
    min-width: 144px;
    min-height: 144px;
    max-height: 144px;
    background: #e8eaf0;
    border-radius: 100px;
    margin: 0px auto 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty .noData .no-data-Icon .Icon:before {
    font-size: 72px;
    content: "\eb2e";
    position: absolute;
    font-family: "boxicons" !important;
    line-height: 34px;
    color: #1a2b68;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty .noData .no-data-Icon .no-data-text {
    color: var(--blue, #1a2b68);
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty .noData .no-data-Icon .no-desc {
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 60%;
    margin: auto;
    margin-bottom: 32px;
}

.main-content .mainCard .main_card_body .table-responsive .table tbody tr .dataTables_empty .noData .no-data-Icon .clear-filter {
    margin: auto;
    height: 36px;
    gap: 10px;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    min-height: 36px;
    max-height: 36px;
    display: flex;
    align-items: center;
    border: 0px;
    background: #1a2b68;
    color: #fff;
}

.main-content .mainCard .main_card_body .dataTables_wrapper {
    padding: 52px 0px 0px;
    position: relative;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    min-height: 36px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label {
    margin: 0px;
    font-size: 0px;
    max-width: 442px;
    min-width: 442px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label:before {
    font-size: 18px;
    content: "\eb2e";
    position: absolute;
    left: 10px;
    font-family: "boxicons" !important;
    line-height: 34px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label input {
    margin-left: 0px;
    width: 100%;
    padding: 8px 42px;
    border-radius: 4px;
    background: #f3f3f9;
    border: 0px;
    outline: 0px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8705882353);
    width: 100%;
    min-height: 36px;
    max-height: 36px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label input ::placeholder {
    font-size: 12px;
    font-weight: 400;
    color: var(--gray);
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_scrollBody {
    overflow-x: hidden !important;
}

.toggle-btn {
    width: 48px;
    height: 24px;
    margin: 0px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    -webkit-transition: background-color 0.4s ease-in-out;
    -moz-transition: background-color 0.4s ease-in-out;
    -o-transition: background-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out;
    border: 2px solid #babcc6;
    cursor: pointer;
}

.toggle-btn.active {
    background-color: #2ecc71;
    border: 2px solid #2ecc71;
}

.toggle-btn.active .round-btn {
    left: 25px;
    background: #fff;
}

.toggle-btn .round-btn {
    width: 18px;
    height: 18px;
    background-color: #babcc6;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 2px;
    top: 50%;
    margin-top: -9px;
    transition: all 0.3s ease-in-out;
}

.toggle-btn .cb-value {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.toggle-btn .Mobile-value-check {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

@media (max-width: 1024px) {
    .main-content .mainCard .main_card_body .table-responsive .table tr th {
        font-size: var(--FONT12) !important;
        line-height: 18px !important;
    }

    .product-management .td-wrapper {
        font-size: var(--FONT12) !important;
        line-height: 18px !important;
    }
}