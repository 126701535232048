body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-data-found {
  text-align: center;
  padding: 100px 0;
}

.no-data-found .no-data-Icon {
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.no-data-found .no-data-Icon .Icon {
  display: flex;
  margin-bottom: 16px;
  position: relative;
}

.no-data-found .no-data-Icon .Icon::before {
  background-color: rgba(255, 0, 254, 0.08);
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  border-radius: 50%;
}

.no-data-found .no-data-Icon .Icon i.bx.bx-search-alt-2.bx-xs {
  font-size: var(--FONT24) !important;
  color: var(--pink);
  background-color: var(--pink-100);
  padding: 15px 8px;
  margin: 8px;
  border-radius: 50%;
}

.no-data-found .no-data-Icon .Icon i.bx.bx-search-alt-2.bx-xs::before {
  background: #ffffff6b;
  padding: 7px;
  border-radius: 50%;
}

.outline_button {
  padding: 8px 16px;
  font-size: var(--FONT14);
  font-weight: 600;
  border: 1px solid var(--pink);
  background-color: var(--white);
  border-radius: 4px;
  color: var(--pink);
}

.no-data-found .no-data-btns.empty-data-btns .outline_button {
  display: none;
}

.no-data-found .no-data-btns.no-found-btn .fill_button {
  display: none !important;
}

.form-control::-webkit-input-placeholder {
  color: #D2D4DA !important;
}

.form-control::-moz-placeholder {
  color: #D2D4DA !important;

}

.form-control:-ms-input-placeholder {
  color: #D2D4DA !important;

}

.form-control::placeholder {
  color: #D2D4DA !important;

}

.phoneinput .form-control {
  width: 100% !important;
  padding-left: 68px !important;
}

.phoneinput .selected-flag {
  max-width: 56px;
  min-width: 56px;
  border-radius: 0px;
  background: transparent;
  padding: 9px;
}

.phoneinput .selected-flag .flag {
  background-size: unset;
  margin-top: -12px !important;
  height: 24px;
}

.phoneinput .selected-flag .flag .arrow {
  border: 0px !important;
  position: relative !important;
  margin: 0px !important;
}

.phoneinput .selected-flag .flag .arrow.up::before {
  content: "\e9e9";
  visibility: visible;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  font-family: boxicons !important;
  line-height: 10px;
}

.phoneinput .selected-flag .flag .arrow::before {
  content: "\e9e2";
  visibility: visible;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  font-family: boxicons !important;
  line-height: 10px;
}

.phoneinput .selected-flag.open:before {
  border-color: transparent;
  box-shadow: 0px 0px;
}

.phoneinput .selected-flag::before {
  background: #eff2f7;
  min-height: 37px;
  max-height: 37px;
  top: 1px;
  border-right: 1px solid;
  left: 1px;
  border-radius: 4px 0px 0px 4px;
}

.phoneinput .country-list .search {
  padding: 10px !important;
}

.phoneinput .country-list .search input.search-box {
  margin: 0px;
  width: 100%;
  color: #292c31 !important;
  min-height: 37px;
  padding: 8px 12px !important;
  font-weight: 400 !important;
  border-radius: 4px;
}

.phoneinput .country-list .search input.search-box::placeholder {
  font-weight: 400 !important;
}

.phoneinput .country-list span.country-name {
  margin-bottom: 7px;
  font-family: unset;
}

.search-error-style {
  font-size: var(--FONT12);
  line-height: 18px;
  color: var(--red);
  position: absolute;
  left: 0;
  top: 52px;
  display: flex;
  height: auto;
  margin-left: 58px;
}

.dropzone input {
  display: block !important;
  opacity: 0 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
}
.badgecount.bg-success{
  padding: 2px 8px;
  font-size: var(--FONT10);
  font-family: 'Poppins';
  line-height: 15px;
  background-color: var(--green) !important;
}