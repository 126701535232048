.order-management .addBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.order-management .table-body-scroll {
  /* width: calc(100% + 6px); */
}

input[type="type=datetime-local"] {
  position: relative;
}

.main-content .order-management .mainCard .main_card_body .table-responsive .table tr:nth-child(2) th {
  background-color: var(--pink-light-bg) !important;
}

.page-title-style {
  display: flex;
}

.page-title-style .back-row-style {
  margin-right: 20px;
}

.order-management .calenderFilterWrapper .order-date-time {
  position: relative;
  border: 1px solid var(--black-10);
  display: flex;
  height: 38px;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--white);
}



.order-management .calenderFilterWrapper input[type=datetime-local]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

.order-management .order-date-time .date-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--white);
  pointer-events: none;
  width: calc(100% - 0px);
  height: 100%;
  padding-top: 7px;
  color: var(--black);
  font-weight: var(--Font-weight-400);
  font-size: var(--FONT14);
  padding-left: 8px;
  padding-right: 8px;
}
.order-management .calenderFilterWrapper .calender input{
  width: 100%;
  height: 38px;
  border: 1px solid var(--black-10);
  border-radius: 4px;
  padding: 8px;
  background-color: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
}
.order-management .calenderFilterWrapper .calender input::placeholder {
  color: var(--black);
}
.product-table thead tr:nth-child(2) th:empty{
  cursor: auto !important;
}
.product-table thead tr .customeSelectWrapper{
  cursor: pointer !important;
}
.order-management .react-datepicker__day--selected{
  background-color: #ABABAB;
  color: var(--white);
  border-radius: 0;
}
.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text{
  font-weight: 400;
}
.order-management .calenderFilterWrapper .calender .react-datepicker-popper{
  width: 243px;
  z-index: 3;
}
.order-management .react-datepicker__header:not(.react-datepicker__header--has-time-select){
  background-color: transparent;
  border-bottom: 0;
}
.order-management .react-datepicker__day:hover{
 border-radius: 0;
}
.order-management .react-datepicker__day--keyboard-selected{
  border-radius: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  color: var(--white);
  fill: var(--white);
}
.order-management .calenderFilterWrapper .calender .react-datepicker{
  width: 100%;
}
.order-management .calenderFilterWrapper{
  position: relative;
}

.order-management .calenderFilterWrapper .calender{
  background-color: var(--white);
  position: relative;
}
.order-management .calenderFilterWrapper .calender .react-datepicker__close-icon::after{
  background-color: transparent;
  color: var(--black);
  content: "\F0156";
  font-family: Material Design Icons;
  font-size: var(--FONT14);
  color: var(--gray-500) !important;
  padding-right: 20px;
}
.order-management .calenderFilterWrapper .calender .react-datepicker__close-icon{
  z-index: 2;
  padding-right: 2px;
}
.order-management .calenderFilterWrapper .calender .react-datepicker-wrapper{
  background-color: transparent;
}
.order-management .calenderFilterWrapper .calender .react-datepicker-wrapper .react-datepicker__input-container{
  background-color: transparent;
}
.order-management .calenderFilterWrapper i{
  position: absolute;
  color: var(--black);
  right: 5px;
  top: 6px;
  font-size: var(--FONT16);
  z-index: 0;
}
.order-management .order-date-time i {
  font-size: var(--FONT16);
  color: var(--black);
  position: absolute;
  top: 5px;
  right: 5px;
  pointer-events: none;
}

.order-management .order-date-time .date-placeholder.date-placeholder-value {
  color: var(--black) !important;
}

.order-management .mainCard .card-body .no-show-list>div:nth-child(2) {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 442px;
}

.order-management .mainCard .card-body .no-show-list>div:nth-child(2) .col-form-label {
  display: none;
}

.order-management .mainCard .card-body .no-show-list .clear-btn-style {
  padding: 0px;
  border: none;
  position: absolute;
  left: 416px;
  z-index: 1;
  top: 6px;
  background: transparent;
}

.order-management .mainCard .card-body .main_card_body .clear-btn-style .mdi-window-close {
  color: var(--black-50);
}

.main-content .order-management .mainCard .main_card_body .table-responsive .table tbody tr td:last-child .td-wrapper i {
  font-size: var(--FONT14);
  color: var(--black);
}



.order-management .productName {
  color: var(--black);
  font-size: var(--FONT14);
  font-weight: 400;
}

.order-management .td-wrapper {
  font-size: var(--FONT14);
}

.order-management .product-table.shimer-row-style tbody tr td {
  display: block !important;
}

.main-content .order-management .mainCard .main_card_body .table-responsive tr .td-wrapper.product-style {
  flex-direction: row;
  align-items: center !important;
}

.main-content .order-management .mainCard .main_card_body .table-responsive tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pendingOrders .table-responsive tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pendingOrders .table-responsive tr th:nth-child(7),
.main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pendingOrders .table-responsive tr td:nth-child(7) {
  display: none;
}

.main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pastOrders .table-responsive tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pastOrders .table-responsive tr th:nth-child(7),
.main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pastOrders .table-responsive tr td:nth-child(7) {
  display: none;
}

.main-content .order-management .mainCard .main_card_body .table-responsive tr td {
  overflow: hidden;
}

.main-content .order-management .mainCard .main_card_body .table-responsive tr td .td-wrapper {
  word-wrap: break-word;
}


.order-management .list-tab-style .nav-item {
  display: flex;
}

.order-management .list-tab-style .tab-content {
  position: relative;
}

.order-management .list-tab-style .tab-content .tab-pane.active {
  padding: 0px;
  background-color: var(--white);
  border: none;
  box-shadow: none;
}


.order-management .list-tab-style .nav-pills .nav-link {
  background-color: var(--white);
  box-shadow: none;
  min-width: 190px;
  text-align: center;
  border-bottom: 2px solid var(--black-25);
  border-radius: 0px;
  font-size: var(--FONT14);
  line-height: 21px;
  font-weight: var(--Font-weight-500);
  color: var(--tab-color);
  display: flex;
}

.order-management .list-tab-style .nav-pills .nav-link .count-number-style {
  margin-left: 10px;
  padding: 2px 10px;
  background-color: var(--black-25);
  border-radius: 4px;
  font-size: var(--FONT12);
  line-height: 18px;
  font-weight: var(--Font-weight-400);
  min-width: 35px;
  height: 22px;
  color: var(--black-50);
}


.order-management .list-tab-style .nav-pills .nav-link.active {
  border-bottom: 2px solid var(--black);
  color: var(--black);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.order-management .list-tab-style .nav-pills .nav-link.active .count-number-style {
  background-color: var(--yellow);
  color: var(--black);
}

.order-management .list-tab-style .status-btn-style.refund-btn {
  background-color: var(--pink);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: var(--FONT14);
  line-height: 21px;
  text-transform: capitalize;
  color: var(--white);
  font-weight: var(--Font-weight-500);
}

.order-management .list-tab-style .status-btn-style.refunded-btn {
  background-color: var(--white);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: var(--FONT14);
  line-height: 21px;
  text-transform: capitalize;
  color: var(--black);
  font-weight: var(--Font-weight-500);
  pointer-events: none;
}

.order-management .list-tab-style .status-btn-style.failed-btn {
  background-color: var(--red);
  padding: 2px 10px;
  border-radius: 20px;
  font-size: var(--FONT12);
  line-height: 18px;
  text-transform: capitalize;
  color: var(--white);
  font-weight: var(--Font-weight-400);
  pointer-events: none;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: transparent;
}


.order-management ::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}


.order-management ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(232, 232, 232, 0.3);
  background-color: rgba(232, 232, 232, 1);
}

.confirm-modal.deactivate.refund-modal .modal-dialog .modal-content .confirm-btn {
  width: 115px;
}


@media (max-width: 1024px) {
  .order-management .productName {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }
}


/* order-details page design css */

.orderstatus-details .heading {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--black-10);
  margin-bottom: 20px;
}

.orderstatus-details .basic-details-row {
  flex-direction: row;
  display: flex;
}


.orderstatus-details .basic-details-row .column-box-style {
  flex: 1;
  display: flex;
  flex-direction: column;
}



.orderstatus-details .basic-details-row .column-box-style:nth-child(5) {
  align-items: flex-end;
}

.orderstatus-details .basic-details-row .column-box-style .label-title-style {
  font-size: var(--FONT14);
  line-height: 20px;
  font-weight: var(--Font-weight-500);
  color: var(--black);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.orderstatus-details .basic-details-row .column-box-style .label-title-style i {
  font-size: var(--FONT20);
  color: var(--black);
  margin-right: 10px;
}

.orderstatus-details .basic-details-row .column-box-style .label-text-style {
  font-size: var(--FONT14);
  line-height: 20px;
  color: var(--black-50);
  word-break: break-word;
}

.orderstatus-details .basic-details-row .column-box-style .location-text-style {
  font-size: var(--FONT12);
  line-height: 18px;
  color: var(--blue);
  margin-bottom: 4px;
  word-wrap: break-word;
}

.orderstatus-details .refunded-btn {
  background-color: var(--white);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: var(--FONT14);
  line-height: 21px;
  text-transform: capitalize;
  color: var(--black);
  font-weight: var(--Font-weight-500);
  pointer-events: none;
}

.orderstatus-details .refund-btn {
  background-color: var(--pink);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: var(--FONT14);
  line-height: 21px;
  text-transform: capitalize;
  color: var(--white);
  font-weight: var(--Font-weight-500);
  cursor: pointer;
}

.status-btn-style.delivered {
  font-size: var(--FONT12);
  line-height: 18px;
  color: var(--white);
  background-color: var(--green);
  align-self: flex-start;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 1px 10px
}

.status-btn-style.no-show {
  font-size: var(--FONT12);
  line-height: 18px;
  color: var(--white);
  background-color: var(--blue);
  align-self: flex-start;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 1px 10px
}

.status-btn-style.pending {
  font-size: var(--FONT12);
  line-height: 18px;
  color: var(--white);
  background-color: var(--yellow);
  align-self: flex-start;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 1px 10px
}

.status-btn-style.failed {
  font-size: var(--FONT12);
  line-height: 18px;
  color: var(--white);
  background-color: var(--red);
  align-self: flex-start;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 1px 10px
}

.orderstatus-details .customer-driver-details {
  display: flex;
  justify-content: space-between;
}

.orderstatus-details .customer-driver-details .middle-table-style {
  width: calc(50% - 12px);
}

.main-content .orderstatus-details .mainCard .main_card_body .table-responsive tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.main-content .orderstatus-details .mainCard .main_card_body .table-responsive .table tr th {
  padding: 8px 10px;
}

.main-content .orderstatus-details .total-amount-row-style {
  display: flex;
  background-color: var(--pink-light-bg);
  min-height: 48px;
}

.main-content .orderstatus-details .total-amount-row-style .label-title-style {
  font-size: var(--FONT14);
  line-height: 21px;
  font-weight: var(--Font-weight-600);
  color: var(--black);
  padding: 10px;
  flex: 2;
}

.main-content .orderstatus-details .total-amount-row-style .amount-text-style {
  font-size: var(--FONT14);
  line-height: 21px;
  font-weight: var(--Font-weight-600);
  color: var(--black);
  padding: 10px 0px 10px 10px;
  flex: 1;
}

.scrollbars-style {
  padding-right: 2px;
}

.scrollbars-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: transparent;
}


.scrollbars-style::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}


.scrollbars-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(232, 232, 232, 0.3);
  background-color: var(--black-25);
}

.orderstatus-details .column-box-style.refundBtnstyle .refund-btn {
  background-color: var(--white);
  border: 1px solid var(--gray);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: var(--FONT14);
  line-height: 21px;
  text-transform: capitalize;
  color: var(--black-50);
  font-weight: var(--Font-weight-500);
  opacity: 0.5;
  cursor: default;
}
.orderstatus-details .column-box-style.refundBtnstyle .amountRefundedStyle{
color: var(--blue);
font-size: var(--FONT12);
line-height: 18px;
font-weight: var(--Font-weight-600);
margin-top: 10px;
}

.orderstatus-details .mainCard .basic-details-row .column-box-style:nth-child(3){
  padding-right: 20px;
}


@media (max-width: 1700px) {
  .main-content .order-management .mainCard .main_card_body .table-responsive tr{
    grid-template-columns: 157px 157px 157px 157px 1fr  1fr 1fr;
  }
  .main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pendingOrders .table-responsive tr{
    grid-template-columns: 185px 185px 185px  1fr  1fr 1fr;
  }
  .main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pastOrders .table-responsive tr {
 
    grid-template-columns: 130px 130px 140px 211px 1fr 1fr;
}
}
@media (max-width: 1440px) {
  .order-management .calenderFilterWrapper {
    width: 90%;
  }
}
@media (max-width: 1439px) {
  .main-content .order-management .mainCard .main_card_body .table-responsive tr{
    grid-template-columns: 105px 180px 196px 175px 1fr  1fr 1fr;
  }
  .main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pendingOrders .table-responsive tr {
    grid-template-columns: 130px 130px 193px 230px 1fr 1fr;
}
}
@media (max-width: 1280px) {
  .main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pendingOrders .table-responsive tr {
    grid-template-columns: 106px 120px 153px 210px 1fr 1fr;
}
}
@media (max-width: 1024px) {
  .main-content .order-management .mainCard .main_card_body .table-responsive tr{
    grid-template-columns: 105px 180px 196px 175px 1fr  1fr 1fr;
  }
  .main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pastOrders .table-responsive tr {
    grid-template-columns: 105px 110px 120px 211px 1fr 1fr;
}
}

@media (max-width: 768px) {
  .order-management .list-tab-style .nav-pills .nav-link{
    min-width: 157px;
    font-size: var(--FONT12);
    padding: 9px 0;
  }
  .main-content .order-management .mainCard .main_card_body .table-responsive tr{
    grid-template-columns: 105px 180px 196px 225px 1fr  1fr 1fr;
  }
  .main-content .order-management .mainCard .main_card_body #left-tabs-example-tabpane-pendingOrders .table-responsive tr {
    grid-template-columns: 104px 100px 133px 211px 1fr 1fr;
}
}


