:root {
  /* Color */
  --white: #ffffff;
  --black: rgba(0, 0, 0, 1);
  --black-50: rgba(0, 0, 0, 0.5);
  --black-25: rgba(0, 0, 0, 0.25);
  --black-10: rgba(0, 0, 0, 0.10);
  --gray: rgba(239, 242, 247, 1);
  --gray-700: rgba(73, 80, 87, 1);
  --blue: rgba(80, 165, 241, 1);
  --green: rgba(52, 195, 143, 1);
  --green-light: rgba(214, 243, 233, 1);
  --pink: rgba(255, 0, 254, 1);
  --pink-200: rgba(255, 0, 254, 0.2);
  --pink-100: rgba(255, 0, 254, 0.1);
  --pink-08: rgba(255, 0, 254, 0.08);
  --pink-light: rgba(255, 186, 244, 1);
  --pink-bg: rgba(255, 233, 241, 1);
  --pink-light-bg: rgba(255, 242, 255, 1);
  --red: rgba(244, 106, 106, 1);
  --yellow: rgba(241, 180, 76, 1);
  --yellow-02: rgba(254, 255, 55, 1);
  --yellow-500: rgba(254, 255, 55, 0.5);
  --yellow-bg: rgba(241, 180, 76, 0.2);
  --dark-purple: #f46a6a1a;
  --tab-color: rgba(116, 120, 141, 0.5);
  --toast-error-bg: #FDE1E1;

  --gray-hover: #d1d1d1;
  --dark-black: #343A40;
  --light-gray: #79829c;
  --sky-blue: #37D3EE;
  --gray-100: #f8f9fa;
  --gray-200: rgba(255, 255, 255, 0.6);
  --white-border: #CED4DA;
  --dark-gray: #495057;
  --green: #34C38F;
  --SatinWhite: #ced4da;
  --black-dark: #292C31;
  --light-pitch: #f3f3f9;
  --PrimaryBlue: #081E1D;
  --TextColorDark: #1F232F;
  --ERROR: #FD3D39;
  --Warning: #F46A6A;
  --Success: #61B006;
  --skyBlue: #50A5F1;
  /* --yellow: #FEFF37; */
  --dark-pink: #FF00FE;
  --light-pink: #FFBAF4;
  --greyBody: #FFF2FF;
  --themeDark: #FDFDFD;

  /*FONTS*/
  --FONT10: 10px;
  --FONT12: 12px;
  --FONT13: 13px;
  --FONT14: 14px;
  --FONT16: 16px;
  --FONT18: 18px;
  --FONT20: 20px;
  --FONT24: 24px;
  --FONT40: 40px;

  /*Font Weight*/
  --Font-weight-700: 700;
  --Font-weight-600: 600;
  --Font-weight-500: 500;
  --Font-weight-400: 400;
  --Font-weight-300: 300;

  --font-family-sans-serif: 'Poppins' !important;

  /*Badge Colors*/
  --Gray-badge-bg: #E5E5E5;
  --Gray-badge-color: #00000080;
  --Green-badge-bg: #D6F3E9;
  --Green-badge-color: #34C38F;
  --Orange-badge-bg: #FCF0DB;
  --Orange-badge-color: #F1B44C;
}