.nofoundPage {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.row {
	margin-bottom: 36px;
}


.pageNotFoundLogo {
	margin-bottom: 36px;
}

.pageNotFoundContent {
	max-width: 644px;
	width: 100%;
	background-color: var(--white);
	border-radius: 4px;

}

.content {
	margin-bottom: 50px;
	margin-top: 0;
}

h1 {
	font-size: 136px;
	line-height: 204.98px;
}

/* i {
	font-size: 120px;
} */

.text-notfound {
	color: var(--pink);
}