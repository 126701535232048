.change-password-page {
  width: 100%;
}

.change-password-page .heading {
  font-size: var(--FONT24);
  line-height: 36px;
  color: var(--black);
}

.change-password-page .change-password-style {
  margin-left: 0px !important;
}

.change-password-page .change-password-style .card-body .p-2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.change-password-page .change-password-style .button-row-style {
  display: flex !important;
}

.change-password-page .change-password-style .button-row-style .custom-btn {
  padding: 8px 16px !important;
  margin-bottom: 10px;
  background-color: var(--pink);
}

.change-password-page .change-password-style .auth-pass-inputgroup #password-addon {
  height: 35px;
}