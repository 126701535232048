.auth-pages {
    padding-top: 80px;

    & .logo-div {
        display: flex;

        & img {
            margin: 0 auto 30px;
            padding: 0px;
        }
    }

    .card {
        max-width: 451px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 8px;
        -webkit-box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        -moz-box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        -ms-box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        -o-box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        background-color: var(--pink);
    }

    .img-fluid {
        max-width: inherit;
        height: auto;
    }

    .logo-image {
        width: 100%;
        text-align: center;
        margin-top: 72px;
        margin-bottom: 48px;
    }

    .fw-semibold {
        color: var(--darkGrey);
    }

    .top-contain-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px 20px 20px;
        background-color: var(--pink) !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        /* margin-bottom: 30px; */

        & h5 {
            &.text-primary {
                color: var(--white) !important;
                font-size: var(--FONT24);
                line-height: 36px;
                font-weight: 600;
                margin-bottom: 0px;
                width: 79%;
            }
        }

        & p {
            margin-bottom: 0px;
            margin-top: 2px;

            &.text-primary {
                color: var(--white) !important;
                font-size: var(--FONT18);
                font-weight: var(--Font-weight-400);
            }
        }


    }

    .Card_Body {
        padding: 12px 22px 2px;

        & .mb-4 {
            margin-bottom: 32px !important;
        }
    }

    .form-check .form-check-input:checked:focus {
        box-shadow: none;
        border: 1px solid var(--black-10) !important;
    }

    .form-check .form-check-input:focus {
        box-shadow: none;
        border: 1px solid var(--black-10) !important;
    }

    .forgotStyle {
        .top-contain-row {
            h5 {
                &.text-primary {
                    margin-bottom: 0px;
                }
            }
        }

        .card-body {
            .forgotTxtStyle {
                padding-top: 0px;
                font-size: var(--FONT14);
                line-height: 24px;
                color: var(--black);
                margin-bottom: 20px;
            }
        }
    }

    .text-primary {
        color: var(--TextColorDark) !important;
        font-size: var(--FONT16);
        font-weight: 600;
        line-height: 24px;
    }

    .btn-row-style {
        align-items: center;

    }


    .cancel-btn-style {
        margin-top: 24px !important;
        background-color: transparent !important;
        color: var(--black-50) !important;
        border-color: transparent !important;
        font-size: var(--FONT14);
        font-weight: 600;
        margin-bottom: 12px;
    }

    .back-txt-style p {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .back-txt-style p a {
        font-size: var(--FONT14);
        color: var(--pink) !important;
        font-weight: var(--Font-weight-600);
        line-height: 20px;
        font-family: var(--font-family-sans-serif);
        text-decoration: none;
    }

    .back-txt-style p i {
        font-size: var(--FONT20);
        color: var(--black);
        margin-right: 10px;
    }

    .form-check .checkbox-main label#checkLabel {
        font-weight: 400;
        color: var(--black);
        font-size: var(--FONT14);
        margin-left: 0px;
        cursor: pointer;
    }


    .copy-right-text-style {
        padding-top: 48px;
        padding-bottom: 10px;
    }

    .copy-right-text-style p {
        font-size: var(--FONT14);
        line-height: 21px;
        color: var(--black);
    }

    .text_green {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--FONT14);
        color: var(--black-50);
        text-decoration: none;
    }

    .text_green i {
        font-size: var(--FONT24);
        line-height: normal;
        color: var(--black-50);
        margin-right: 8px !important;
    }

    .otp-form .otp-container {
        /* padding: 0px; */
        justify-content: space-between;
    }

    .otp-form .otp-container .otp-input-none-style {
        width: 76px !important;
        height: 48px;
        border: 1px solid var(--black);
        background-color: var(--white);
        border-radius: 4px;
        font-size: var(--FONT16);
        font-weight: 500;
        line-height: 30px;
        color: var(--black);
        background-color: var(--white);
    }

    .otp-form .otp-container .otpInputFillStyle {
        width: 76px !important;
        height: 48px;
        border: 1px solid var(--black-10);
        background-color: var(--greyDark);
        border-radius: 6px;
        font-size: var(--FONT16);
        font-weight: 500;
        line-height: 30px;
        color: var(--PrimaryBlue);
        background-color: var(--white);
    }

    .resend-code-style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .resend-code-style .btn {
        background-color: transparent !important;
        color: var(--pink) !important;
        border: none;
        padding: 0px;
        font-weight: var(--Font-weight-600) !important;
        font-size: var(--FONT14);
    }

    .resend-code-style p {
        margin-bottom: 0px;
        margin-right: 8px;
        font-size: var(--FONT14);
        line-height: 21px;
        color: var(--darkGrey);
        font-weight: 400;
    }

    .resend-code-timer-style {
        color: var(--black-50) !important;
        font-weight: var(--Font-weight-600) !important;
        font-size: var(--FONT14);
    }

    .resend-code-timer-style span {
        font-weight: 600;
        text-decoration: underline;
        color: var(--blue);
        font-size: var(--FONT14);
        padding-left: 5px;
    }

}