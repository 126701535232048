.product-management .addBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 0;
  border-bottom: 0;
}

.products-number-style {
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-600);
  color: var(--black-50);
}

.product-management .mainCard .card-body .main_card_body>div:nth-child(2) {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 442px;
}

.product-management .mainCard .card-body .main_card_body>div:nth-child(2) .col-form-label {
  display: none;
}

.product-management .mainCard .card-body .main_card_body .clear-btn-style {
  padding: 0px;
  border: none;
  position: absolute;
  left: 431px;
  z-index: 1;
  top: 21px;
  background: transparent;
}

.product-management .mainCard .card-body .main_card_body .clear-btn-style .mdi-window-close {
  color: var(--black-50);
}

.main-content .product-management .mainCard .main_card_body .table-responsive .table tbody tr td:last-child .td-wrapper i {
  font-size: var(--FONT14);
  color: var(--black);
}



.product-management .productName {
  color: var(--black);
  width: 100%;
  font-size: var(--FONT14);
  font-weight: 400;
}

.product-management .td-wrapper {
  font-size: var(--FONT14);
}

.product-management .product-table.shimer-row-style tbody tr td {
  display: block !important;
}
.main-content .product-management .mainCard .main_card_body .table-responsive tr{
cursor: pointer;
cursor: auto;
}
.main-content .product-management .mainCard .main_card_body .table-responsive tr .td-wrapper.product-style .product-image-style {
  margin-right: 10px;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content .product-management .mainCard .main_card_body .table-responsive tr .td-wrapper.product-style .product-image-style img {
  height: 32px;
  border-radius: 4px;
  min-width: 32px;
}

.main-content .product-management .mainCard .main_card_body .table-responsive tr .td-wrapper.product-style {
  flex-direction: row;
  align-items: center !important;
}

.main-content .product-management .mainCard .main_card_body .table-responsive tr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.main-content .product-management .mainCard .main_card_body .table-responsive tr td {
  overflow: hidden;
  flex-wrap: wrap;
  /* word-break: break-all; */
}

.main-content .product-management .mainCard .main_card_body .table-responsive tr td .td-wrapper {
  /* overflow: auto; */
}

.add-edit-customer-modal .addEditWrapper .wrapper-input textarea {
  height: 80px;
  resize: none;
  font-size: var(--FONT14) !important;
  color: var(--black) !important;

}

.add-edit-customer-modal .addEditWrapper .wrapper-input .amount-style {
  position: absolute;
  left: 12.5px;
  top: 7px;
  font-size: var(--FONT14);
}

.add-edit-customer-modal .TextFiledWrapper.wrapper-input.amount-input-style input {
  padding-left: 25px;
}

.add-edit-customer-modal .wrapper-input.img-upload {
  display: flex;
  margin-bottom: 10px;
}
.add-edit-customer-modal .wrapper-input.img-upload .btn-loader{
  min-width: 148px;
  height: 148px;
}

.add-edit-customer-modal .wrapper-input.img-upload .btn-loader img{
  width: 148px;
  height: 148px;
}
.add-edit-customer-modal .dropzone {
  /* min-height: 193px; */
  grid-template-columns: 144px 1fr;
  display: grid;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px;
  min-height: 144px;
  border: 1px solid var(--black-10);
  border-radius: 10px;
  background-color: var(--gray);
  position: relative;
}

.add-edit-customer-modal .wrapper-input.img-upload .dropzone img {
  border-radius: 10px;
  width: 144px;
  height: 144px;
}

.add-edit-customer-modal .wrapper-input.img-upload .dropzone .btn-loader img {
  width: 100px;
  height: 100px;
}

.add-edit-customer-modal .wrapper-input.img-upload .dropzone .dropZoneWrapper .bx-image {
  font-size: var(--FONT40);
  color: var(--black-50);
}

.right-contain-style {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
  flex-direction: column;
  gap: 10px;
}

.right-contain-style.error-msg .title-upload,
.right-contain-style.error-msg .text-image-size {
  color: var(--ERROR);
}

.right-contain-style .title-upload {
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-500);
  color: var(--black);
  line-height: 20px;
}

.right-contain-style .text-image-size {
  font-size: var(--FONT10);
  font-weight: var(--Font-weight-500);
  line-height: 20px;
  opacity: 0.5;
  display: contents;
}

.add-edit-customer-modal .camera-cricle-style {
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -10px;
  border: 1px solid var(--black-10);
  width: 32px;
  height: 32px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-edit-customer-modal .camera-cricle-style i {
  color: var(--black-50);
  font-size: var(--FONT16);
}

.add-edit-customer-modal .dropzone .progress-wrapper {
  width: 100%;
  padding: 100px 0px 0px 0px;
  margin-bottom: 20px;
}

.add-edit-customer-modal .dropzone .progress-wrapper .progress {
  background: var(--DropDownBorder);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0px;
  display: flex;
  height: 5px;
  width: 100%;
}

.add-edit-customer-modal .dropzone .progress-wrapper .progress .progress-value {
  animation: load 3s normal forwards;
  border-radius: 100px;
  background: var(--PrimaryBlue);
  height: 5px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.add-edit-customer-modal .dropzone .progress-wrapper p {
  margin-top: 20px;
  margin-bottom: 0px;
  color: var(--darkGray);
  font-size: var(--FONT14);
  font-weight: var(--font-weight-normal);
}

@media (max-width: 1024px) {
  .product-management .productName {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }
}


.order-management .addBtn.height {
  height: 60px;
}

.order-management .search-error-style {
  top: 38px;
  margin-left: 42px;
}

.add-edit-customer-modal .addEditWrapper .wrapper-input textarea:focus{
  box-shadow: none;
  border-color: var(--black-10) !important;
}

/* CSS FOR CROP IMAGE */
.add-edit-customer-modal .wrapper-input .fileUpload {
  position: relative;
  cursor: pointer;
  width: 150px;
  height: 150px;
  border: 1px solid var(--black-10);
  border-radius: 10px;
  background-color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-edit-customer-modal .wrapper-input .fileUpload img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: 1px solid var(--black-10);
}

.add-edit-customer-modal .wrapper-input .fileUpload .bx-image {
  font-size: var(--FONT40);
  color: var(--black-50);
}

.add-edit-customer-modal .wrapper-input .upload {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.controls {
  margin-left: 7.5px;
  width: 130px;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  top: -5px;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid var(--white);
  border-bottom: 5px solid var(--white);
  background: var(--dark-pink);
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  background: var(--dark-pink);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--dark-pink);
  background: var(--dark-pink);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(255, 0, 254, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(255, 0, 254, 0.16);
}

.zoom-range {
  width: 100%;
  height: 6px;
  margin: 10px 0;
  background-color: var(--light-pitch);
  border: none;
  border-radius: 3px;
  outline: none;
}