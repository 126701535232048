.notification-page {
  width: 100%;
  max-width: 1024px;
}

.notification-page .main_card_body {
  padding-right: 4px;
}

.notification-page ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: transparent;
}


.notification-page ::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}


.notification-page ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(232, 232, 232, 0.3);
  background-color: var(--black-10);
}

.notification-page .page-title-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.notification-page .page-title-style .heading {
  padding-bottom: 0px;
}

.notification-page .page-title-style .btn-read-btn {
  border: none;
  background-color: transparent;
  border-radius: 0px;
  font-size: var(--FONT14);
  line-height: 21px;
  font-weight: var(--Font-weight-600);
  color: var(--pink);
  padding: 0px;
  cursor: pointer;
}

.notification-page .main_card_body .title-style {
  font-size: var(--FONT14);
  line-height: 21px;
  font-weight: var(--Font-weight-600);
  color: var(--black-50);
  width: 100%;
  display: flex;
  text-transform: uppercase;
  margin: 20px 0 10px;
}

.list-card-style {
  width: 100%;
  display: flex;
  border-radius: 4px;
  background-color: var(--white);
  padding: 12px 10px;
  margin-bottom: 8px;
}

.contain-left-style {
  flex: 1;
  padding-right: 10px;
}

.contain-left-style .notification-title-style {
  font-size: var(--FONT14);
  line-height: 21px;
  font-weight: var(--Font-weight-600);
  color: var(--black);
  margin-bottom: 5px;
  position: relative;
  width: fit-content;
}

.contain-left-style .notification-txt-style {
  width: fit-content;
  font-size: var(--FONT14);
  line-height: 21px;
  font-weight: var(--Font-weight-400);
  color: var(--black-50);
  position: relative;
}
.contain-right-style .text-style{
  width: fit-content;
}
.contain-right-style {
  padding-right: 40px;
  position: relative;
  width: 170px;
}

.list-card-style.unread-style {
  background-color: #ffffde;
  border-radius: 4px;
}

.list-card-style.unread-style .contain-right-style::before {
  position: absolute;
  right: 2px;
  top: 4px;
  width: 8px;
  height: 8px;
  background-color: var(--green);
  border-radius: 5px;
  content: '';
}

.contain-right-style .text-style {
  font-size: var(--FONT12);
  line-height: 18px;
  color: var(--black-50);
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  position: relative;
}

.contain-right-style .text-style i {
  font-size: var(--FONT16);
  color: var(--black-50);
  margin-right: 10px;
}

.contain-right-style .text-style:last-child {
  margin-bottom: 0px;
}


/* shimer Place Holder */
.shimer {
  background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
  animation: placeholderShimmer 2s linear 0s infinite normal forwards;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}