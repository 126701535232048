
  .driver-table tbody tr:last-child  .wrapper .tooltip {
  background: #000;
  top: -40px;
  color: #fff;
  display: block;
  border-radius: 4px;
  left: -45px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 5px;
  pointer-events: none;
  position: absolute;
  width: 180px;
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
     -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
     -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
     -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  }
  
  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  .driver-table tbody tr:last-child .wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
  }
  
  /* CSS Triangles - see Trevor's post */
  .driver-table tbody tr:last-child .wrapper .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #000 10px;
    border-bottom: none;
    bottom: -10px;
    top: 30px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
  }
  
  .driver-table tbody tr:last-child .wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .driver-table tbody tr:last-child .lte8 .wrapper .tooltip {
  display: none;
  }
  
  .driver-table tbody tr:last-child .lte8 .wrapper:hover .tooltip {
  display: block;
  }


  .driver-table tbody .wrapper {
    position: relative;
    -webkit-transform: translateZ(0); /* webkit flicker fix */
    -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
    }



.wrapper {
  position: relative;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
  }
  
  .wrapper .tooltip {
  background: #000;
  top: 129%;
  color: #fff;
  display: block;
  border-radius: 4px;
  left: -45px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 5px;
  pointer-events: none;
  position: absolute;
  width: 180px;
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
     -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
     -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
     -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  }
  
  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  /* .wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
  } */
  
  /* CSS Triangles - see Trevor's post */
  .wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #000 10px;
  top: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
  }
  
  .wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .wrapper .tooltip {
  display: none;
  }
  
  .lte8 .wrapper:hover .tooltip {
  display: block;
  }





.heading {
  color: var(--dark-gray);
  font-weight: 600;
  font-size: var(--FONT16);
  text-transform: uppercase;
  padding-bottom: 24px;
}

.pink-btn {
  background-color: var(--pink);
  border-color: transparent !important;
  color: var(--white) !important;
  border-radius: 4px !important;
  font-size: var(--FONT14);
  font-weight: 600;
  padding: 6px 16px 6px 16px;
}
.driver-schedule-trip .disabled {
  pointer-events: none;
  opacity: 0.5;
}
.driver-schedule-trip .disabled {

}
.tripBtn {
  border: 1px solid black;
  text-align: center;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  font-weight: 600;
  color: #000;
  font-size: 14px;
  cursor: pointer;
}

.disableTd .tripBtn {
  opacity: 0.5;
}

.Button-disable {
  background-color: transparent;
  border-color: transparent !important;
  color: rgba(0, 0, 0, 0.60) !important;
  border-radius: 4px !important;
  font-size: var(--FONT14);
  font-weight: 600;
  padding: 8px 16px 8px 16px;
}



.form-label {
  margin-bottom: 0px;
}

.requiredStyle {
  margin-bottom: 0px;
  color: var(--Warning);
}

.mainDiv {
  display: flex;
  gap: 10px;
}

.main-content .mainCard {
  border-radius: 4px;
  border: 1px solid #e9ebec;
  background-color: var(--white) !important;
}

/* .main-content .mainCard .table-responsive {
  height: 500px !important;
  overflow-y: scroll !important;
} */

.main-content .mainCard .main_card_body {
  padding: 15px;
}

.addBtn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  height: 53px;
  border-bottom: 1px solid var(--Gray-badge-bg);
  margin-bottom: 16px;
}

/* .main-content .mainCard .table-responsive {
  height: 600px !important;
  overflow-y: auto !important;
} */

.main-content .mainCard .main_card_body .row.my-2 {
  margin: 0px !important;
}

.main-content .mainCard .main_card_body .row .mb-2 {
  margin-bottom: 0px !important;
}

.main-content .mainCard .main_card_body .row .col-sm-auto input.form-control::placeholder {
  font-size: var(--FONT12);
  font-weight: 400;
  color: #BFBFBF;
}

.main-content .mainCard .main_card_body .row .btn-transparent {
  border: 0px;
  position: absolute;
  right: 0;
  min-height: 36px;
  max-height: 36px;
  padding: 0px 10px;
}

.main-content .mainCard .main_card_body .row .btn-transparent:before {
  content: "\f0156";
  font: normal normal normal 24px/1 Material Design Icons;
  font-size: 20px;
  color: var(--gray);
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content .mainCard .main_card_body .row .btn-transparent svg {
  opacity: 0;
  display: none;
}

.main-content .mainCard .main_card_body .row .btn-transparent:disabled {
  opacity: 0;
}

.main-content .mainCard .main_card_body .Card-Button {
  position: absolute;
  top: 34px;
  right: 34px;
}

.mainCard .mainCard .table-responsive {
  padding-top: 16px;
  border-top: 1px solid var(--gray-100);
  overflow-y: scroll;
  margin-bottom: 30px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table {
  margin-bottom: 0px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody{
  overflow: auto;
}

.main-content .mainCard .main_card_body .table-responsive tr {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  cursor: pointer;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table .table-body-scroll {
  display: table-caption;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tr th {
  height: auto !important;
  background-color: var(--black);
  color: var(--white);
  font-size: var(--FONT14);
}



.main-content .mainCard .main_card_body .table-responsive .driver-table tr th div .th-count {
  padding: 4px 10px 4px 10px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  background-color: var(--yellow);
  margin-left: 10px;
  color: var(--black);
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tr:nth-child(2) th {
  background-color: var(--white) !important;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td p {
  font-size: var(--FONT14);
  font-weight: 400;
  margin-bottom: 0px;
  position: relative;
  line-height: 20px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .driver-wrapper {
  display: flex;
  align-items: flex-start !important;
  position: relative;
  line-height: 20px;
  flex-direction: column;
}

.driverName {
  color: var(--dark-gray);
  font-size: var(--FONT14);
  font-weight: 600;
  word-break: keep-all;
  overflow: auto;
}

.tripStatus {
  color: var(--gray-800);
  padding: 10px;
}

.abc {
  background-color: var(--gray-500);
  padding: 1px 10px;
  width: fit-content;
  border-radius: 10px;
  opacity: 0.8;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tr:nth-child(2) th {
  background-color: var(--white) !important;
}


.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td {
  background-color: var(--white);
  padding: 10px;
  border-bottom: 1px solid #f4f4f7;
  font-size: var(--FONT14);
  font-weight: 400;
  margin-bottom: 0px;
  color: var(--black-dark);
  display: flex;
  align-items: center;
  /* overflow: hidden; */
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td p {
  font-size: var(--FONT14);
  font-weight: 400;
  margin-bottom: 0px;
  position: relative;
  line-height: 20px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .driver-wrapper {
  display: flex;
  align-items: center;
  /* grid-gap: 10px; */
  position: relative;
  line-height: 20px;
  word-break: keep-all;
  /* overflow: auto; */
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .driver-wrapper i {
  font-size: var(--FONT20);
  line-height: 0;
  color: var(--dark-gray);
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  position: relative;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper .form-switch {
  padding-left: 0px;
  margin-bottom: 0px;
}

/* 
.form-switch .form-check-input {
  background-color: var(--errorGreen) !important;
  border: 1px solid transparent !important;
  background-image: url('https://res.cloudinary.com/zudu/image/upload/v1696913346/Ellipse.svg') !important;
  font-size: 20px !important;
  filter: opacity(1) !important;
} */



.form-check-input:focus {
  border: none !important;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper .form-switch .form-check-input:checked {
  background-color: var(--green) !important;
  filter: opacity(1);
  border: none;
  /* background-image: url('https://res.cloudinary.com/zudu/image/upload/v1696914538/Ellipse_gray.svg') !important;
  border: 1px solid transparent;
  font-size: 20px;
  */
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper .form-switch .form-check-input {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 20px;
  background-color: var(--white);
  border-color: var(--gray);
  filter: opacity(0.5);
}


.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper .Edit-Buttton {
  background: transparent;
  border: 0px;
  /* color: var(--Info); */
  padding: 0px;
  line-height: 0px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper .Edit-Buttton i:before {
  content: "\f0cb6";
  font: normal normal normal 24px/1 Material Design Icons;
  color: var(--skyBlue);
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper .Delete-Buttton {
  background: transparent;
  border: 0px;
  color: var(--red);
  padding: 0px;
  line-height: 0px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td .action-wrapper .Delete-Buttton i:before {
  font-size: var(--FONT24);
  color: var(--Warning);
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td.disableTd {
  background-color: #f4f4f7 !important;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td.disableTd p {
  color: #b8bdd0;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td.disableTd .driver-wrapper {
  color: #b8bdd0;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr td.disableTd .driver-wrapper i {
  color: #b8bdd0;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty {
  border-bottom: 0px !important;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty .noData {
  padding: 10px 0px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty .noData .no-data-Icon {
  display: grid;
  width: fit-content;
  margin: auto;
  text-align: center;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty .noData .no-data-Icon .Icon {
  max-width: 144px;
  min-width: 144px;
  min-height: 144px;
  max-height: 144px;
  background: #e8eaf0;
  border-radius: 100px;
  margin: 0px auto 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty .noData .no-data-Icon .Icon:before {
  font-size: 72px;
  content: "\eb2e";
  position: absolute;
  font-family: "boxicons" !important;
  line-height: 34px;
  color: #1a2b68;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty .noData .no-data-Icon .no-data-text {
  color: var(--blue, #1a2b68);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty .noData .no-data-Icon .no-desc {
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 60%;
  margin: auto;
  margin-bottom: 32px;
}

.main-content .mainCard .main_card_body .table-responsive .driver-table tbody tr .dataTables_empty .noData .no-data-Icon .clear-filter {
  margin: auto;
  height: 36px;
  gap: 10px;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  padding: 8px 16px !important;
  border-radius: 4px !important;
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  border: 0px;
  background: #1a2b68;
  color: #fff;
}

.main-content .mainCard .main_card_body .dataTables_wrapper {
  padding: 52px 0px 0px;
  position: relative;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  min-height: 36px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label {
  margin: 0px;
  font-size: 0px;
  max-width: 442px;
  min-width: 442px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label:before {
  font-size: 18px;
  content: "\eb2e";
  position: absolute;
  left: 10px;
  font-family: "boxicons" !important;
  line-height: 34px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label input {
  margin-left: 0px;
  width: 100%;
  padding: 8px 42px;
  border-radius: 4px;
  background: #f3f3f9;
  border: 0px;
  outline: 0px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8705882353);
  width: 100%;
  min-height: 36px;
  max-height: 36px;
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_filter label input ::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: var(--gray);
}

.main-content .mainCard .main_card_body .dataTables_wrapper .dataTables_scrollBody {
  overflow-x: hidden !important;
}

.toggle-btn {
  width: 48px;
  height: 24px;
  margin: 0px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-in-out;
  -moz-transition: background-color 0.4s ease-in-out;
  -o-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
  border: 2px solid #babcc6;
  cursor: pointer;
}

.toggle-btn.active {
  background-color: #2ecc71;
  border: 2px solid #2ecc71;
}

.toggle-btn.active .round-btn {
  left: 25px;
  background: #fff;
}

.toggle-btn .round-btn {
  width: 18px;
  height: 18px;
  background-color: #babcc6;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 2px;
  top: 50%;
  margin-top: -9px;
  transition: all 0.3s ease-in-out;
}

.toggle-btn .cb-value {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.toggle-btn .Mobile-value-check {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.addEditWrapper {}

.addEditWrapper~.Form_buton {}

.addEditWrapper~.Form_buton .fill_button {
  margin: unset;
}

.TextFiledWrapper {
  margin-bottom: 24px;
}

.addEditWrapper .TextFiledWrapper {
  position: relative;
}

/* .addEditWrapper .TextFiledWrapper input {
  padding-right: 45px !important;
} */

.phone {
  color: var(--gray-700);
}

.PhoneInput {
  border: 1px solid var(--white-border);
  background-color: var(--greyDark) !important;
  color: var(--darkGrey) !important;
  box-shadow: none !important;
  font-size: var(--FONT14);
  padding: .375rem .75rem;
  border-radius: 4px;
}

.PhoneInputInput {
  border: none;
}

.addEditWrapper .TextFiledWrapper .ClearInput {
  position: absolute;
  height: 100%;
  min-height: 37px;
  max-height: 37px;
  right: 1px;
  padding: 0px 15px;
  top: 1px;
  background-color: var(--white);
  z-index: 11;
  opacity: 1;
  font-size: 10px;
}

.addEditWrapper .TextFiledWrapper {
  margin-bottom: 24px;
}

.driver-management .mainCard .card-body .main_card_body>div:nth-child(2) {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 442px;
}

.driver-management .mainCard .card-body .main_card_body>div:nth-child(2) .col-form-label {
  display: none;
}

.driver-management .mainCard .card-body .main_card_body .clear-btn-style {
  padding: 0px;
  border: none;
  position: absolute;
  left: 431px;
  z-index: 1;
  top: 21px;
  background: transparent;
}

.driver-management .mainCard .card-body .main_card_body .clear-btn-style .mdi-window-close {
  color: var(--black-50);
}


.driver-details {
  width: 100%;
}

.driver-details .heading {
  width: 100%;
  border-bottom: 1px solid var(--black-10);
  padding-bottom: 20px;
  color: var(--black);
  display: flex;
  align-items: center;
}

.driver-details .heading .th-count {
  padding: 4px 10px 4px 10px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  background-color: var(--yellow);
  margin-left: 10px;
  color: var(--black);
}

.driver-details .driver-details-row {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.driver-details .column-box-style {
  width: 33.333%;
  padding: 20px 0;
}

.driver-details .column-box-style .label-title-style {
  display: flex;
  align-items: center;
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-500);
  color: var(--black);
}

.driver-details .column-box-style .label-title-style i {
  font-size: var(--FONT20);
  line-height: 20px;
  margin-right: 10px;
}

.driver-details .column-box-style:nth-child(1) .label-text-style {
  color: var(--black);
  font-weight: 600;
}

.driver-details .column-box-style .label-text-style {
  font-size: var(--FONT14);
  line-height: 21px;
  margin-top: 10px;
  color: var(--black-50);
  word-break: break-word;
}

.driver-wrapper {
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-400);
  line-height: 20px;
}
.driver-table .driver-wrapper .tripBtn {
  &.disabled{
    pointer-events: none;
    opacity: 0.4;
  }
}

.main-content .driver-details .mainCard .main_card_body .table-responsive tr td:nth-child(6) .driver-wrapper {
  font-weight: var(--Font-weight-600);
}

.driver-details .tdWrapper,
.driver-management .tdWrapper {
  padding: 0 10px;
  border-radius: 10px;
  font-size: var(--FONT12);
  line-height: 18px;
}


.driver-details .completed,
.driver-management .completed {
  background-color: var(--green-light);
  color: var(--green);
}


.driver-details .scheduled,
.driver-management .scheduled {
  background-color: var(--yellow-bg);
  color: rgba(241, 180, 76, 1);


}


.driver-details .not-scheduled,
.driver-management .not-scheduled {
  background-color: var(--black-10);
  color: var(--black-50);


}

.main-content .driver-details .mainCard .main_card_body .table-responsive tr {
  display: grid;
  grid-template-columns: 140px 140px 140px 1.5fr 1fr 1fr 1fr;
}

.driver-details .action-wrapper .view-buttton-orders {
  border: none;
  background-color: transparent;
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-600);
  color: var(--blue)
}

.driver-schedule-trip .heading {
  border-bottom: 1px solid var(--black-10);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.driver-schedule-trip .Form_buton {
  padding-top: 16px;
  border-top: 1px solid var(--black-10);
}

.driver-schedule-trip .schedule-trip-left-part .form-label {
  margin-bottom: 4px;
}

.driver-schedule-trip .schedule-trip-left-part .driver-name-style {
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-600);
  line-height: 21px;
  color: var(--black);
  margin-bottom: 24px;
}

.driver-schedule-trip .schedule-trip-left-part .driver-id-style {
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-400);
  line-height: 21px;
  color: var(--black);
  margin-bottom: 24px;
}

.schedule-trip-main {
  display: flex;
  width: 100%;
}

.driver-schedule-trip .cancel-btn {
  background-color: transparent;
  padding: 5px 16px;
  color: var(--black-50);
  font-weight: var(--Font-weight-600);
  height: 37px;
}

.schedule-trip-left-part {
  width: 50%;
  padding-right: 24px;
}

.schedule-trip-left-part .range-slider-style {
  width: 100%;
  padding: 0 5px;
}

.schedule-trip-left-part .range-slider-style .rc-slider-track {
  background-color: var(--pink);
}

.schedule-trip-left-part .range-value {
  font-size: var(--FONT14);
  color: var(--black);
  font-weight: var(--Font-weight-500);
  margin-bottom: 10px;
}

.schedule-trip-left-part .range-slider-style .value-row-style {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.value-count-style {
  font-size: var(--FONT12);
  line-height: 16px;
  padding: 0 8px;
  background-color: var(--black-10);
  color: var(--black-50);
}

.rc-slider {
  width: 100%;
}

.schedule-trip-left-part .date-wrapper {
  margin-bottom: 24px;
  position: relative;
}

.schedule-trip-left-part .time-wrapper .mdi {
  font-size: var(--FONT20);
  color: var(--black);
  position: absolute;
  right: 10px;
  top: 9px;
  z-index: -1;
}

.schedule-trip-left-part .time-wrapper {
  margin-bottom: 24px;
  position: relative;
}

.schedule-trip-left-part .range-slider-style .rc-slider-handle {
  border-radius: 0px;
  box-shadow: none;
  transform: rotate(45deg) !important;
  /* width: 16px;
  height: 16px; */
  border-color: var(--pink);
}

/* .schedule-trip-left-part .date-wrapper {
  position: relative;
} */

.schedule-trip-left-part .date-wrapper input {
  /* font-size: 0px !important; */
  /* min-height: 36px;
  max-height: 36px; */
  padding: 13px 12px;
  text-transform: uppercase;
}

.schedule-trip-left-part .time-wrapper input {
  /* font-size: 0px !important; */
  /* min-height: 36px;
  max-height: 36px; */
  padding: 13px 12px;
}

.schedule-trip-left-part .time-wrapper input::-ms-input-placeholder,
.schedule-trip-left-part .date-wrapper input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #BFBFBF;
}

.schedule-trip-left-part .time-wrapper input::placeholder,
.schedule-trip-left-part .date-wrapper input::placeholder {
  color: #BFBFBF;
}

.schedule-trip-left-part .time-wrapper button{
  border-color: var(--black-10);
  background-color: transparent !important;
  color: var(--darkGrey) !important;
  box-shadow: none !important;
  font-size: var(--FONT14);
  width: 100%;
  border-radius: 4px !important;
  border: 1px solid var(--black-10);
  text-align: justify;
  height: 49px;
  padding: 13px 12px;
}
/* 
.schedule-trip-left-part .date-wrapper input[type=datetime-local] {
  background: transparent;
  cursor: pointer;

}

.schedule-trip-left-part .date-wrapper p {
  position: absolute;
  top: 50%;
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  margin-top: -10.5px;
  padding: 0px 12px;
} */

.schedule-trip-right-part {
  width: 50%;
}

.schedule-trip-right-part .google-dropdown-style {
  width: 100%;
  position: relative;
}

.schedule-trip-right-part .google-dropdown-style #react-select-2-placeholder {
  font-size: var(--FONT14);
  opacity: 0.5;
}

.schedule-trip-right-part .google-dropdown-style .css-1fdsijx-ValueContainer {
  padding: 7px 8px;
}

.schedule-trip-right-part .google-dropdown-style .css-1dimb5e-singleValue {
  font-size: var(--FONT14);
}

.schedule-trip-right-part .google-dropdown-style .bx.bx-target-lock {
  position: absolute;
  right: 12px;
  top: 11px;
  font-size: var(--FONT20);
}

.schedule-trip-right-part .google-dropdown-style input {
  width: 100%;
  border-radius: 4px !important;
  font-size: var(--FONT14) !important;
}

.schedule-trip-right-part .map-view-style {
  margin-top: 10px;
  width: 100%;
  height: 480px;
}

.schedule-trip-right-part .pin-style {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(255, 186, 244, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-trip-right-part .pin-style i {
  font-size: var(--FONT40);
  color: var(--pink);
}

input[type="time"] {
  /* position: relative; */
}


input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  /* position: absolute; */
  /* top: 0; */
  /* right: 0; */
  font-size: var(--FONT20);
  /* width: 30px;
  height: 30x; */
  padding: 0;
  color: var(--black);
  /* background: transparent; */
  cursor: pointer;
}

.date-wrapper.placehoderStyle input {
  /* color: var(--black-25) !important; */
}

.date-wrapper.placehoderStyle .form-control.is-invalid, .date-wrapper.placehoderStyle .was-validated .form-control:invalid { 
  background-position: right calc(.375em + 2rem) center;
}

.driver-management .driver-table.shimer-row-style tbody tr td {
  display: block !important;
}
.main-content .driver-management .mainCard .main_card_body .table-responsive .table tr th:nth-child(3) {
  display: none;
}
.main-content .driver-management .mainCard .main_card_body .table-responsive .table tr td:nth-child(3) {
  display: none;
}
.time-wrapper input{
  width: 100%;
  font-size: var(--FONT14);
  /* border-color: var(--black-10); */
}
.time-wrapper input{
  border:1px solid var(--black-10) ;
  border-radius: 4px ;
  background-color: transparent;
}
/* .calender .react-datepicker-popper{
  z-index: 2;
  transform: translate(0px, 58px) !important;
}
.calender .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  position: absolute;
  pointer-events: none;
  left: 66px !important;
  transform: rotate(180deg) translateY(-1px) !important;
  bottom: 100% !important;
} */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  right: 5px !important; 
  left: auto !important;
  fill: var(--white);
  color: var(--white);
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle{
  left: 4px !important;
}
.schedule-trip-left-part .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
  right: 5px !important; 
  left: auto !important;
  fill: #fee9f1;
  color: #fee9f1;
}
.calender .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
  color: var(--black) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.schedule-trip-left-part .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
  background-color: #FFEAFC !important;
}
.schedule-trip-left-part .react-datepicker__time-list-item.react-datepicker__time-list-item--selected{
  background-color: #fee9f1 !important;
}
.schedule-trip-left-part .react-datepicker__header{
  background-color: #fee9f1 !important;

}
.calender .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled{
  color: #00000079 !important;
}
.calender .react-datepicker{
  width: 150px;
}
.calender .react-datepicker--time-only .react-datepicker__time-container{
  width: 100%;
}
.calender .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: 100%;
}
.main-content .driver-schedule-trip .mainCard{
z-index: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  /* background: transparent; */
  bottom: 0;
  /* color: transparent; */
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  background-position: 100% 0px;
  background-size: 20px;
  right: 10px;
  top: 15px;
  width: auto;
}
.custom-clear-indicator-container i{
  margin-right: 10px;
}


@media (max-width: 1440px) {

  .main-content .driver-management .mainCard .main_card_body .table-responsive tr {
    grid-template-columns: repeat(6, 1fr);
  }

  .main-content .driver-details .mainCard .main_card_body .table-responsive tr {
    grid-template-columns: 133px 133px 133px 1.5fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1024px) {
  .driverName {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .phone {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .driver-wrapper {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .tripBtn {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .driver-schedule-trip .schedule-trip-left-part .driver-name-style {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .driver-schedule-trip .schedule-trip-left-part .form-label {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .schedule-trip-right-part .google-dropdown-style #react-select-2-placeholder {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .driver-schedule-trip .Form_buton {
    padding-top: 10px;
    margin-top: 10px;
  }

  .driver-schedule-trip .heading {
    padding-bottom: 10px !important;
  }
}

@media (max-width: 1024px) {
  .main-content .mainCard .main_card_body .table-responsive .table tbody tr td .action-wrapper {
    grid-gap: 4px !important;
  }
}

@media (max-width: 810px) {
  .schedule-trip-right-part {
    width: 100%;
  }

  .schedule-trip-main {
    flex-direction: column;
  }

  .schedule-trip-left-part {
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
  }
    .main-content .driver-management .mainCard .main_card_body .table-responsive tr {
      grid-template-columns: 150px 130px 120px 120px 120px 120px;
  }
  
}

@media (max-width: 768px) {
  .driver-management .mainCard .card-body .main_card_body>div:nth-child(2) {
    top: 10px;
    left: 10px;
  }

}

.driver-management .addBtn.height {
  height: 60px;
}

@-moz-document url-prefix() {
  .schedule-trip-left-part .disabled .time-wrapper .mdi{
    opacity: .4;
  }
  .date-wrapper.placehoderStyle .form-control.is-invalid, .date-wrapper.placehoderStyle .was-validated .form-control:invalid{
    background-position: right calc(.375em + 0rem) center;
  }
  .date-wrapper.placehoderStyle .form-control.is-invalid{
    padding-right: calc(1.5em + 0.5rem) !important;
  }
  .schedule-trip-left-part  .time-wrapper .mdi{
    opacity: .6;
  }
}