.customer-management .addBtn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  height: 53px;
}

.customer-management .addBtn.height {
  height: 60px;
}

.customer-management .mainCard .card-body .main_card_body>div:nth-child(2) {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 442px;
}

.customer-management .mainCard .card-body .main_card_body>div:nth-child(2) .col-form-label {
  display: none;
}

.customer-management .mainCard .card-body .main_card_body .clear-btn-style {
  padding: 0px;
  border: none;
  position: absolute;
  left: 431px;
  z-index: 1;
  top: 21px;
  background: transparent;
}

.customer-management .mainCard .card-body .main_card_body .clear-btn-style .mdi-window-close {
  color: var(--black-50);
}

.main-content .customer-management .mainCard .main_card_body .table-responsive .table tbody tr td:last-child {
  justify-content: center;
}

.main-content .customer-management .mainCard .main_card_body .table-responsive .table tbody tr td {
  overflow: hidden;
}

/* .main-content .customer-management .mainCard .main_card_body .table-responsive .table tbody tr td .td-wrapper {
  overflow: auto;
} */

.main-content .customer-management .mainCard .main_card_body .table-responsive .table tbody tr td:last-child .td-wrapper {
  overflow: visible;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-content .customer-management .mainCard .main_card_body .table-responsive .table tbody tr td:last-child .td-wrapper i {
  font-size: var(--FONT14);
  color: var(--black);
  align-self: center;
  display: flex;
}

.customer-management .customer-table.shimer-row-style tbody tr td {
  display: block !important;
}

.customer-management .driverName {
  color: var(--black);
  font-size: var(--FONT14);
  font-weight: 400;
}

.customer-management .td-wrapper {
  font-size: var(--FONT14);
}
.main-content .customer-management .mainCard .main_card_body .table-responsive tr{
  cursor: auto;
}
.main-content .customer-management .mainCard .main_card_body .table-responsive tr td:last-child{
  cursor: pointer;
}
@media (max-width: 1440px) {
  .main-content .customer-management .mainCard .main_card_body .table-responsive tr {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .main-content .customer-management .mainCard .main_card_body .table-responsive .table tbody tr td:last-child {
    justify-content: left;
  }

  .main-content .customer-management .mainCard .main_card_body .table-responsive .table tbody tr td:last-child .td-wrapper {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center !important;
    position: relative;
    justify-content: center;
  }
}
@media (max-width: 1280px) {
  .main-content .customer-management .mainCard .main_card_body .table-responsive tr {
    grid-template-columns: 1fr 1fr 1fr 97px 97px 97px;
}
}
@media (max-width: 1024px) {
  .customer-management .td-wrapper {
    font-size: var(--FONT12);
    line-height: 18px;
  }

  .customer-management .driverName {
    font-size: var(--FONT12);
    line-height: 18px;
  }
  
}

@media (max-width: 992px) {
  .main-content .customer-management .mainCard .main_card_body .table-responsive tr {
    grid-template-columns: 1fr 1fr 1fr 73px 73px 73px;
}
}
@media (max-width: 768px) {
  .customer-management .mainCard .card-body .main_card_body>div:nth-child(2) {
    top: 10px;
    left: 10px;
  }
}