@import "./variables.css";
@import "../css/icons/materialdesignicons.css";
@import "../css/icons/boxicons.css";
@import "../css/icons/fontawesome-all.css";
@import "../css/icons/dripicons.css";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

body {
  font-family: Poppins !important;

  a:hover {
    text-decoration: unset;
  }
}

:root {
  --sidebar-width: 252px;
  --header-height: 70px;
  --sidebar-collapsed-width: 70px;
  --sidebar-width-sm: 252px;
  --layout-collapsed-min-height: 1760px;
  --grid-gutter-width: 24px;
  /* topbar */
  --prefix-heading-bg: #ffffff;
  --prefix-topbar-search-bg: #f3f3f9;
  --prefix-header-item-color: #555b6d;
  --prefix-boxed-body-bg: #ebebf4;
  --prefix-topnav-bg: #ffffff;
  --prefix-menu-item-color: #545a6d;
  --prefix-heading-dark-bg: #2a3042;
  --prefix-input-border-color: #cccccc;
  --prefix-input-focus-border-color: #b3b3b3;
  --variable-prefix-form-check-input-border: rgba(0, 0, 0, 0.1);

  /* footer */
  --prefix-footer-bg: #f2f2f5;
  --prefix-footer-color: #74788d;
  --prefix-custom-white: #ffffff;
  --prefix-hr-border-color: #cccccc;
}

.infinate-load {
  text-align: center;
  padding: 10px 15px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 30px);
  left: 0px;
  z-index: 9999;
  background: white;

}

.table-shimer {
  display: flex;
  width: 100%;
}

.table-shimer tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.table-shimer tr .loading-shimer-col {
  position: relative;
  display: flex;
  width: 19%;
  height: 38px;
}

.infinate-load .text-primary {
  color: var(--pink) !important;
}


.padding4-8 {
  padding: 4px 8px 4px 8px
}

.padding8-16 {
  padding: 8px 16px 8px 16px
}

.font14 {
  font-size: var(--FONT14);
  font-weight: var(--Font-weight-600);
}

.bgPink {
  background-color: var(--pink);
}

.txtColor {
  color: var(--white);
}

.bgDarkGreen {
  background-color: var(--black);
}


#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: var(--sidebar-width-sm);
  z-index: 1;
  background-color: var(--white);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.btn-close:focus{
  box-shadow: none !important;
}
@media (max-width: 1025px) {
  #page-topbar {
    left: 0;
  }
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: var(--header-height);
  padding: 0px 40px 0px;
  background-color: var(--pink);
}
.sidebar-enable.vertical-collpsed .navbar-header{
  padding: 0px 40px 0px 00px;
}
.navbar-header .burger-menu {
  border-color: transparent !important;
}

.navbar-header .dropdown.show.header-item {
  /* background-color: var(--#{$prefix}gray-100); */
}

.vertical-collpsed .vertical-menu .metis-menu {
  padding: 14px 20px !important;
}

.vertical-collpsed .metis-menu li {
  margin: 22px 0px;
}

.navbar-header div:nth-child(2) a {
  display: flex;
  align-items: center;
  font-size: var(--FONT14);
  color: var(--black);
}

.navbar-header div:nth-child(2) a:nth-child(1) {
  color: var(--gray);
  margin-right: 12px;
}

.navbar-header div:nth-child(2) .top-right-menu {
  cursor: pointer;
  margin-left: 12px;
}

.navbar-header div:nth-child(2) .top-right-menu i {
  margin-left: 10px;
}


.col-sm-auto input.form-control::placeholder {
  font-size: var(--FONT12);
  font-weight: var(--font-weight-normal);
  color: var(--Auth_Top_Back_Color);
}

.col-sm-auto input:focus {
  background: var(--greyBody) !important;

}

.green {
  color: var(--green);
}

.border_button {
  background: transparent !important;
  color: var(--pink) !important;
  font-size: var(--FONT14) !important;
  font-style: normal;
  font-weight: 600 !important;
  padding: 8px 16px !important;
  border: 1px solid var(--pink) !important;
  border-radius: 4px !important;
  min-height: 36px;
  max-height: 36px;
  display: flex !important;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.fill_button {
  font-size: var(--FONT14) !important;
  font-style: normal;
  font-weight: 600 !important;
  padding: 8px 16px !important;
  border-radius: 4px !important;
  min-height: 36px;
  max-height: 36px;
  display: flex !important;
  align-items: center;
  width: fit-content;
  margin: auto;
  background: transparent;
  border: 0px;
  width: fit-content;
  color: var(--white);
  background: var(--pink);
  position: relative;
  overflow: hidden;
}

.App {
  text-align: center;
}

.form-label {
  font-family: var(--font-family-sans-serif);
  color: var(--black);
  font-weight: var(--Font-weight-500);
  font-size: var(--FONT14);
  line-height: 20px;

  &.requiredStyle {
    color: var(--red);
  }
}

.custom-btn {
  opacity: 1 !important;
  border-color: transparent !important;
  border-radius: 4px !important;
  font-size: var(--FONT14);
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  

}

.custom-btn:disabled {
  background-color: #c9c9c9 !important;
  border-color: transparent !important;
  color: var(--black-50) !important;
  opacity: 0.50 !important;
  border-radius: 4px !important;
  font-weight: var(--Font-weight-600);
  font-size: var(--FONT14);
}

.title-icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--black-10);
}

.PhoneInputCountry {
  pointer-events: none;
}

.confirm-modal .title-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: none;
}

.logout .modal-content .modal-body .modal-title h4 {
  color: var(--Warning);
}

.confirm-modal .modal-dialog .modal-content .modal-body {
  padding: 20px 20px;
}

.confirm-modal .modal-dialog {
  max-width: 395px;
}

.confirm-modal .modal-dialog .modal-content {
  border: none;
  display: block;
  text-align: center;
  font-size: var(--FONT14);
  color: var(--black);
  margin-bottom: 4px;
  /* word-break: break-word; */

}

.confirm-modal .modal-dialog .modal-content .modal-body .icon-cricle-style {
  margin: auto;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 20px;
  background: var(--black-10);
}

.confirm-modal .modal-dialog .modal-content .modal-body .icon-cricle-style i {
  color: var(--black-50);
  line-height: 45px;
  font-size: 45px;
}

.confirm-modal .modal-dialog .modal-content .modal-body .icon-cricle-style.icon-cricle-delete {
  background: var(--dark-purple);
}

.confirm-modal .modal-dialog .modal-content .modal-body .icon-cricle-style.icon-cricle-delete i {
  color: var(--red);
  line-height: 45px;
  font-size: 45px;
}

.modal-title {
  justify-content: center;
  align-items: center !important;
  align-self: center !important;
  text-align: center !important;
}

.modal-title h4 {
  font-weight: 600;
  font-size: var(--FONT20);
  line-height: 30px;
  color: var(--black);
  margin-bottom: 0px;
}

.main-btn-div {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.confirm-modal .modal-dialog .modal-content .confirm-btn {
  background-color: var(--black-50);
  padding: 5px 14px;
  color: var(--white);
  font-weight: var(--Font-weight-600);
  margin-right: 20px;
  height: 37px;
  width: 90px;
}

.confirm-modal .modal-dialog .modal-content .cancel-btn {
  background-color: transparent;
  padding: 5px 16px;
  color: var(--black-50);
  font-weight: var(--Font-weight-600);
  opacity: 0.5 !important;
  height: 37px;
  width: 90px;
}

.confirm-modal .modal-dialog .modal-content .delete-btn {
  background-color: var(--red);
  border-color: transparent !important;
  color: var(--white) !important;
  border-radius: 4px !important;
  font-size: var(--FONT14);
  font-weight: 600;
  padding: 6px 16px 6px 16px;
  margin-right: 20px;
  width: 90px;
}


.form-check .form-check-input:checked {
  background-color: var(--black) !important;
  border-color: var(--black) !important;
}

.form-check-input {
  background-color: var(--white) !important;
  border-color: var(--black-10) !important;
}

.navbar-brand-box {
  padding: 0;
  text-align: center;
  width: var(--sidebar-width-sm);
}

.logo {
  line-height: 0px;
}

.logo .logo-sm {
  line-height: 70px;
  display: none;
}

.logo-light {
  display: none;
}

.app-search {
  /* padding: calc(#{$header-height - 38px} * 0.5) 0; */

  .form-control {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    box-shadow: none;
    border-radius: 30px;
    font-size: var(--FONT12);
  }

  span {
    position: absolute;
    z-index: 10;
    font-size: var(--FONT16);
    line-height: 38px;
    left: 13px;
    top: 0;
    /* color: var(--#{$prefix}gray-600); */
  }
}

.megamenu-list li {
  position: relative;
  padding: 5px 0px;
}

.megamenu-list li a {
  /* color: var(--#{$prefix}body-color); */
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo .logo-lg {
    display: none;
  }

  .logo .logo-sm {
    display: inline-block;
  }
  .sidebar-enable.vertical-collpsed .navbar-header {
    padding: 0px 40px 0px 80px;
}
}

.page-content {
  padding: calc(var(--header-height) + var(--grid-gutter-width)) calc(var(--grid-gutter-width)) 0px calc(var(--grid-gutter-width));
  /* background-color: var(--greyBody); */
}

.profile-dropdown-style button {
  padding: 0px;
  background-color: transparent !important;
  border: 0px solid transparent;
  display: flex;
  align-items: center;
}

.profile-dropdown-style .header-profile-user {
  height: 36px;
  width: 36px;
}

.profile-dropdown-style .profile-name-style {
  padding: 0px 10px;
  font-size: var(--FONT15);
  color: var(--white);
  font-weight: 600;
}

.profile-dropdown-style i {
  line-height: 24px;
  font-size: var(--FONT24);
  color: var(--white);
}

.profile-dropdown-style i.setting {
  color: var(--yellow-02);
}

.burger-menu {
  color: var(--white) !important;
}

.profile-dropdown-style .dropdown-menu {
  width: 200px;
  padding: 0px;
  border: none;
  overflow: hidden;
  border-radius: 10px;
  top: 22px !important;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.12);
  -moz-box-shadow: 0px 5px 20px 0px rgba(18, 38, 63, 0.12);
  -o-box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.12);
  box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.12);
}

.profile-dropdown-style .dropdown-menu a {
  padding: 10px 18px;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  background-color: var(--white) !important;
}

.profile-dropdown-style .dropdown-menu a i {
  line-height: 24px;
  font-size: var(--FONT20);
  color: var(--black);
}

.profile-dropdown-style .dropdown-menu a span {
  color: var(--ERROR);
}


/* // Dropdown with Icons */
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  /* color: var(--#{$prefix}gray-600); */

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    /* border-color: var(--#{$prefix}border-color); */
  }
}

/* // Full Screen */
.fullscreen-enable {
  [data-bs-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\ea3f";
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout="horizontal"] {
  .navbar-brand-box {
    width: auto;
  }

  .page-content {
    margin-top: var(--header-height);
    padding: 0;
  }
}

@media (max-width: 992px) {
  body[data-layout="horizontal"] {
    .page-content {
      margin-top: 70px;
    }
  }
}

/* // < ===================================== End top bar Component css ===================================== > */

/* // < ===================================== sidebar Logo Component css ===================================== > */
.sidebar-logo-set {
  width: 100%;
  text-align: center;
  background: #fff;
}

.sidebar-logo-set img {
  max-width: 150px;
}

/* // < ===================================== End sidebar Logo Component css ===================================== > */
.metis-menu {
  margin: 0;
  padding: 24px !important;
}

.metis-menu li {
  display: block;
  margin: 12px 0px;
  width: 100%;
}

.metis-menu .mm-collapse {
  display: none;
}

.metis-menu .mm-collapse:not(.mm-show) {
  display: none;
}

.metis-menu .mm-collapse.mm-show {
  display: block;
}

.metis-menu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: height, visibility;
}


.vertical-menu {
  width: var(--sidebar-width-sm);
  z-index: 1001;
  background: var(--light-pink);
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding-top: var(--header-height);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.dropdown-menu a:nth-child(1) span{
color: var(--black);
}
@media (max-width: 1024px) {
  .vertical-menu {
    top: var(--header-height);
    padding-top: 0px;
  }
}

.vertical-menu .navbar-brand-box {
  position: fixed;
  top: 0px;
  z-index: 99;
  background: var(--pink);
  padding: 11px 10px 10px;
  min-height: 70px;
  max-height: 70px;
}

.vertical-menu .navbar-brand-box .logo-lg img {
  /* max-width: 115px;
  height: auto; */
  /* width: 100%; */
}

@media (max-width: 1024px) {
  .vertical-menu .navbar-brand-box {
    /* display: none; */
  }
}

.main-content {
  margin-left: var(--sidebar-width-sm);
  overflow: hidden;
  /* // height: 100vh;
  */
  transition: 0.1s all;
}

.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: var(--header-height);
}


#sidebar-menu {
  padding: 0;
  position: relative;
}

#sidebar-menu .mm-active>.has-arrow:after {
  transform: rotate(-180deg);
}

#sidebar-menu .has-arrow:after {
  content: "\F0140";
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: transform 0.1s;
  font-size: 1rem;
  position: absolute;
  right: 0;
}

#sidebar-menu ul li a .left-menu svg {
  color: var(--white);
  fill: var(--white);
}

#sidebar-menu ul li:hover a .left-menu svg,
#sidebar-menu ul li:hover a .left-menu span {
  color: var(--PrimaryBlue);
  fill: var(--PrimaryBlue);
}

#sidebar-menu ul li .navbar-brand-box .logo {
  padding: 0.688rem 1.5rem;
}

#sidebar-menu ul li a {
  display: flex;
  flex-direction: row;
  color: #fff;
  text-decoration: none;
  position: relative;
  font-size: var(--FONT14);
  transition: all 0.1s;
  border: 0.5px solid transparent;
  border-radius: 8px;
  padding: 10px;
}

#sidebar-menu ul li a .left-menu {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  /* margin: auto; */
  /* opacity: 0.5; */
  align-content: center !important;
  align-items: center !important;
}

.active {
  color: #000 !important;
  background: rgba(255, 0, 254, 0.20);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  border: 0.5px solid #000;
  border-radius: 8px;
  padding: 10px;
}

#sidebar-menu ul li a .left-menu svg {
  margin: 0px 12px 0 0;
  color: var(--white);
}

.vertical-collpsed #sidebar-menu ul li a .left-menu svg {
  margin: 0px 0px 0 0;
  color: var(--white);
}

#sidebar-menu ul li a .left-menu i {
  margin-bottom: 6px;
}

#sidebar-menu ul li a .left-menu span {
  color: var(--black);
  font-size: var(--FONT14);
}

#sidebar-menu ul li a .num {
  border: 1px solid var(--Success);
  padding: 0 10px;
  border-radius: 30px;
}

#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 1.75rem;
  font-size: 1.25rem;
  line-height: 1.30625rem;
  vertical-align: middle;
  color: var(--white);
  transition: all 0.1s;
}

#sidebar-menu ul li a:hover {
  color: var(--gray-hover);
}

#sidebar-menu ul li a:hover i {
  color: var(--gray-hover);
}

#sidebar-menu ul li .badge {
  margin-top: 4px;
}

#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li {
  margin: 8px 0;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 0rem 0.4rem 2rem;
  font-size: var(--FONT14);
  /* color: var(--sidebar-menu-sub-item-color); */
}

#sidebar-menu ul li ul.sub-menu li a:hover {
  color: var(--gray-hover);
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
  font-size: var(--FONT14);
}

.menu-title {
  padding: 20px 0px 0px !important;
  margin: 0 !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: var(--FONT12);
  text-transform: uppercase;
  color: var(--pink) !important;
  /* color: #fff 80; */
  font-weight: var(--font-weight-semibold);
  /* text-align: center; */
}

li {
  color: var(--white) !important;
}

.sub-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
}

/* Style for the submenu when it's open */
.sub-menu.open {
  max-height: 500px;
  /* Adjust the value based on your content height */
}

li .active {
  color: var(--white) !important;
  border-radius: 8px;
  color: #000 !important;
  background: rgba(255, 0, 254, 0.20);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  border: 0.5px solid #000 !important;
  border-radius: 8px;
  padding: 10px;
}

li .active .left-menu svg,
li .active .left-menu span {
  fill: var(--PrimaryBlue) !important;
  color: var(--PrimaryBlue) !important;
}

li .active .left-menu {
  opacity: 1 !important;
}


@media (max-width: 1024px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable .vertical-menu {
    display: block;
  }
}


/* // Enlarge menu */
.vertical-collpsed {
  /* // Side menu */
}

.vertical-collpsed .main-content {
  margin-left: var(--sidebar-width-sm);
}

.vertical-collpsed .navbar-brand-box {
  width: var(--sidebar-collapsed-width) !important;
  padding: 0;
}

.vertical-collpsed .navbar-brand-box .logo {
  padding: 0 !important;
}

.vertical-collpsed .navbar-brand-box .logo a {
  padding: 0;
}

.vertical-collpsed #page-topbar {
  left: var(--sidebar-collapsed-width);
}

@media (max-width: 991.98px) {
  .vertical-collpsed #page-topbar {
    left: 0;
  }
}

.col-form-label {
  margin: 0px;
  font-size: var(--FONT12);
  padding: 0px;
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
  min-height: 36px;
  z-index: 1;
}

.col-form-label~.col-sm-auto {
  position: relative;
}

.col-form-label~.col-sm-auto:before {
  font-size: var(--FONT18);
  content: "\eb2e";
  font-family: "boxicons" !important;
  color: var(--black-50);
  height: 100%;
  display: flex;
  align-items: center;
  left: 25px;
  position: absolute;
}

.col-sm-auto input.form-control {
  margin-left: 0px;
  width: 100%;
  padding: 8px 42px;
  border-radius: 30px !important;
  background: var(--gray) !important;
  border: 0px;
  outline: 0px;
  font-size: var(--FONT12) !important;
  font-weight: var(--font-weight-normal);
  color: var(--black) !important;
  width: 100%;
  min-height: 36px;
  max-height: 36px;
  max-width: 442px;
  min-width: 442px;
}

.col-sm-auto input.form-control::placeholder {
  font-size: var(--FONT12);
  font-weight: var(--font-weight-normal);
  color: var(--black-50) !important;
}

.col-sm-auto input:focus {
  background: var(--gray) !important;
}

.btn-transparent {
  border: 0px;
  position: absolute;
  right: 0;
  min-height: 36px;
  max-height: 36px;
  padding: 0px 10px;
  visibility: hidden;
}

.btn-transparent:before {
  content: "\F0156";
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: var(--FONT20);
  color: var(--white);
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-transparent svg {
  opacity: 0;
  display: none;
}

.btn-transparent:disabled {
  opacity: 0;
}

.vertical-collpsed .logo span.logo-lg {
  display: none;
}

.vertical-collpsed .logo span.logo-sm {
  display: block;
}

.vertical-collpsed .vertical-menu {
  /* // position: absolute;
	 */
  width: var(--sidebar-width-sm) !important;
  z-index: 5;
  /* // Sidebar Menu */
}

.vertical-collpsed .vertical-menu .simplebar-mask,
.vertical-collpsed .vertical-menu .simplebar-content-wrapper {
  overflow: visible !important;
}

.vertical-collpsed .vertical-menu .simplebar-scrollbar {
  display: none !important;
}

.vertical-collpsed .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .menu-title,
.vertical-collpsed .vertical-menu #sidebar-menu .badge,
.vertical-collpsed .vertical-menu #sidebar-menu .collapse.in {
  /* display: none !important; */
}

.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after {
  display: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li {
  position: relative;
  white-space: nowrap;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
  padding: 10px 10px;
  min-height: 35px;
  transition: none;
  transition: 0.1s all;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:hover,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:active,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:focus {
  color: var(--gray-hover);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i {
  font-size: 1.45rem;
  margin-left: 4px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
  /* display: none; */
  padding: 0 10px ;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  position: relative;
  width: 100%;
  color: #fff !important;
  /* background-color: transparent; */
  transition: none;
  border-radius: 8px;
  display: flex;
  justify-content: space-between !important;
  padding: 10px 10px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a .left-menu {
  /* background-color: #fcbaf4; */
  border-radius: 4px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a .num {
  padding: 0 10px !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i {
  color: var(--white);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a span {
  display: inline;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul {
  display: block;
  left: var(--sidebar-collapsed-width);
  position: absolute;
  width: 190px;
  height: auto !important;
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul ul {
  box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a {
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  /* color: var(--sidebar-menu-sub-item-color); */
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a:hover {
  color: var(--gray-hover);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: var(--white);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li:hover>ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li>a span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li.active a {
  color: var(--gray-100);
}


body[data-sidebar="dark"] .vertical-menu {
  background: var(--dark-black);
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
  /* color: var(--sidebar-dark-menu-item-color); */
}

body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: var(--white);
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover {
  color: var(--sky-blue);
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
  color: var(--sky-blue);
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: var(--light-gray);
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: var(--sky-blue);
}

body[data-sidebar="dark"].vertical-collpsed {
  min-height: var(--layout-collapsed-min-height);
  /* // Side menu */
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu {
  /* // Sidebar Menu */
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background: var(--dark-black);
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover {
  /* background: lighten(var(--dark-black), 2%);
	 */
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  color: var(--sky-blue);
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i {
  color: var(--sky-blue);
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a {
  color: var(--light-gray);
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul a:hover {
  color: var(--sky-blue);
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu>ul ul {
  background-color: var(--dark-black);
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"] .mm-active {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"] .mm-active>a {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"] .mm-active>a i {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"] .mm-active>i {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"] .mm-active .active {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"] .mm-active .active i {
  color: var(--sky-blue) !important;
}

body[data-sidebar="dark"] .menu-title {
  color: var(--Auth_Top_Back_Color);
}

body[data-layout="horizontal"] .main-content {
  margin-left: 0 !important;
}

/* // Compact Sidebar */
body[data-sidebar-size="small"] .navbar-brand-box {
  width: var(--sidebar-width-sm);
}

body[data-sidebar-size="small"] .vertical-menu {
  width: var(--sidebar-width-sm);
  text-align: center;
}

body[data-sidebar-size="small"] .vertical-menu .has-arrow:after,
body[data-sidebar-size="small"] .vertical-menu .badge {
  display: none !important;
}

body[data-sidebar-size="small"] .main-content {
  margin-left: var(--sidebar-width-sm);
}

body[data-sidebar-size="small"] .footer {
  left: var(--sidebar-width-sm);
}

@media (max-width: 991.98px) {
  body[data-sidebar-size="small"] .footer {
    left: 0;
  }
}

body[data-sidebar-size="small"] #page-topbar {
  left: var(--sidebar-width-sm);
}

@media (max-width: 991.98px) {
  body[data-sidebar-size="small"] #page-topbar {
    left: 0;
  }

  body[data-sidebar-size="small"] #page-topbar .navbar-header .navbar-brand-box {
    width: auto;
  }
}

body[data-sidebar-size="small"] #sidebar-menu ul li a i {
  display: block;
}

body[data-sidebar-size="small"] #sidebar-menu ul li ul.sub-menu li a {
  padding-left: 1.5rem;
}

body[data-sidebar-size="small"] #sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding-left: 1.5rem;
}

body[data-sidebar-size="small"].vertical-collpsed .main-content {
  margin-left: var(--sidebar-width-sm);
}

body[data-sidebar-size="small"].vertical-collpsed .vertical-menu #sidebar-menu {
  text-align: left;
}

body[data-sidebar-size="small"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i {
  display: inline-block;
}

body[data-sidebar-size="small"].vertical-collpsed .footer {
  left: var(--sidebar-collapsed-width);
}


/* // colored sidebar */
body[data-sidebar="colored"] .navbar-brand-box .logo-dark,
body[data-sidebar="winter"] .navbar-brand-box .logo-dark,
body[data-sidebar="ladylip"] .navbar-brand-box .logo-dark,
body[data-sidebar="plumplate"] .navbar-brand-box .logo-dark,
body[data-sidebar="strongbliss"] .navbar-brand-box .logo-dark,
body[data-sidebar="greatwhale"] .navbar-brand-box .logo-dark {
  display: none;
}

body[data-sidebar="colored"] .navbar-brand-box .logo-light,
body[data-sidebar="winter"] .navbar-brand-box .logo-light,
body[data-sidebar="ladylip"] .navbar-brand-box .logo-light,
body[data-sidebar="plumplate"] .navbar-brand-box .logo-light,
body[data-sidebar="strongbliss"] .navbar-brand-box .logo-light,
body[data-sidebar="greatwhale"] .navbar-brand-box .logo-light {
  display: block;
}

body[data-sidebar="colored"] .mm-active,
body[data-sidebar="winter"] .mm-active,
body[data-sidebar="ladylip"] .mm-active,
body[data-sidebar="plumplate"] .mm-active,
body[data-sidebar="strongbliss"] .mm-active,
body[data-sidebar="greatwhale"] .mm-active {
  color: var(--white) !important;
}

body[data-sidebar="colored"] .mm-active>a,
body[data-sidebar="winter"] .mm-active>a,
body[data-sidebar="ladylip"] .mm-active>a,
body[data-sidebar="plumplate"] .mm-active>a,
body[data-sidebar="strongbliss"] .mm-active>a,
body[data-sidebar="greatwhale"] .mm-active>a {
  color: var(--white) !important;
}

body[data-sidebar="colored"] .mm-active>a i,
body[data-sidebar="winter"] .mm-active>a i,
body[data-sidebar="ladylip"] .mm-active>a i,
body[data-sidebar="plumplate"] .mm-active>a i,
body[data-sidebar="strongbliss"] .mm-active>a i,
body[data-sidebar="greatwhale"] .mm-active>a i {
  color: var(--white) !important;
}

body[data-sidebar="colored"] .mm-active>i,
body[data-sidebar="winter"] .mm-active>i,
body[data-sidebar="ladylip"] .mm-active>i,
body[data-sidebar="plumplate"] .mm-active>i,
body[data-sidebar="strongbliss"] .mm-active>i,
body[data-sidebar="greatwhale"] .mm-active>i,
body[data-sidebar="colored"] .mm-active .active,
body[data-sidebar="winter"] .mm-active .active,
body[data-sidebar="ladylip"] .mm-active .active,
body[data-sidebar="plumplate"] .mm-active .active,
body[data-sidebar="strongbliss"] .mm-active .active,
body[data-sidebar="greatwhale"] .mm-active .active {
  color: var(--white) !important;
}

body[data-sidebar="colored"] #sidebar-menu ul li.menu-title,
body[data-sidebar="winter"] #sidebar-menu ul li.menu-title,
body[data-sidebar="ladylip"] #sidebar-menu ul li.menu-title,
body[data-sidebar="plumplate"] #sidebar-menu ul li.menu-title,
body[data-sidebar="strongbliss"] #sidebar-menu ul li.menu-title,
body[data-sidebar="greatwhale"] #sidebar-menu ul li.menu-title {
  color: rgba(0, 1, 0, );
}

body[data-sidebar="colored"] #sidebar-menu ul li a,
body[data-sidebar="winter"] #sidebar-menu ul li a,
body[data-sidebar="ladylip"] #sidebar-menu ul li a,
body[data-sidebar="plumplate"] #sidebar-menu ul li a,
body[data-sidebar="strongbliss"] #sidebar-menu ul li a,
body[data-sidebar="greatwhale"] #sidebar-menu ul li a {
  color: rgba(0, 1, 0, );
}

body[data-sidebar="colored"] #sidebar-menu ul li a i,
body[data-sidebar="winter"] #sidebar-menu ul li a i,
body[data-sidebar="ladylip"] #sidebar-menu ul li a i,
body[data-sidebar="plumplate"] #sidebar-menu ul li a i,
body[data-sidebar="strongbliss"] #sidebar-menu ul li a i,
body[data-sidebar="greatwhale"] #sidebar-menu ul li a i {
  color: rgba(0, 1, 0, );
}

body[data-sidebar="colored"] #sidebar-menu ul li a.waves-effect .waves-ripple,
body[data-sidebar="winter"] #sidebar-menu ul li a.waves-effect .waves-ripple,
body[data-sidebar="ladylip"] #sidebar-menu ul li a.waves-effect .waves-ripple,
body[data-sidebar="plumplate"] #sidebar-menu ul li a.waves-effect .waves-ripple,
body[data-sidebar="strongbliss"] #sidebar-menu ul li a.waves-effect .waves-ripple,
body[data-sidebar="greatwhale"] #sidebar-menu ul li a.waves-effect .waves-ripple {
  background: rgba(0, 0, 0, );
}

body[data-sidebar="colored"] #sidebar-menu ul li a:hover,
body[data-sidebar="winter"] #sidebar-menu ul li a:hover,
body[data-sidebar="ladylip"] #sidebar-menu ul li a:hover,
body[data-sidebar="plumplate"] #sidebar-menu ul li a:hover,
body[data-sidebar="strongbliss"] #sidebar-menu ul li a:hover,
body[data-sidebar="greatwhale"] #sidebar-menu ul li a:hover {
  color: var(--white);
}

body[data-sidebar="colored"] #sidebar-menu ul li a:hover i,
body[data-sidebar="winter"] #sidebar-menu ul li a:hover i,
body[data-sidebar="ladylip"] #sidebar-menu ul li a:hover i,
body[data-sidebar="plumplate"] #sidebar-menu ul li a:hover i,
body[data-sidebar="strongbliss"] #sidebar-menu ul li a:hover i,
body[data-sidebar="greatwhale"] #sidebar-menu ul li a:hover i {
  color: var(--white);
}

body[data-sidebar="colored"] #sidebar-menu ul li ul.sub-menu li a,
body[data-sidebar="winter"] #sidebar-menu ul li ul.sub-menu li a,
body[data-sidebar="ladylip"] #sidebar-menu ul li ul.sub-menu li a,
body[data-sidebar="plumplate"] #sidebar-menu ul li ul.sub-menu li a,
body[data-sidebar="strongbliss"] #sidebar-menu ul li ul.sub-menu li a,
body[data-sidebar="greatwhale"] #sidebar-menu ul li ul.sub-menu li a {
  color: rgba(0, 1, 0, );
}

body[data-sidebar="colored"] #sidebar-menu ul li ul.sub-menu li a:hover,
body[data-sidebar="winter"] #sidebar-menu ul li ul.sub-menu li a:hover,
body[data-sidebar="ladylip"] #sidebar-menu ul li ul.sub-menu li a:hover,
body[data-sidebar="plumplate"] #sidebar-menu ul li ul.sub-menu li a:hover,
body[data-sidebar="strongbliss"] #sidebar-menu ul li ul.sub-menu li a:hover,
body[data-sidebar="greatwhale"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: var(--white);
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a,
body[data-sidebar="winter"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a,
body[data-sidebar="ladylip"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a,
body[data-sidebar="plumplate"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a,
body[data-sidebar="strongbliss"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a,
body[data-sidebar="greatwhale"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  color: var(--white);
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i,
body[data-sidebar="winter"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i,
body[data-sidebar="ladylip"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i,
body[data-sidebar="plumplate"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i,
body[data-sidebar="strongbliss"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i,
body[data-sidebar="greatwhale"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i {
  color: var(--white);
}





/* // colored sidebar */
.bg-colored {
  background-color: var(--primary) !important;
}

body[data-sidebar="colored"] .vertical-menu {
  background-color: var(--primary);
}

@media (max-width: 1024px) {
  body[data-sidebar="colored"] .navbar-brand-box {
    background-repeat: repeat-x;
  }
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background-color: var(--primary);
}

body[data-sidebar="colored"][data-sidebar-size="small"] .menu-title {
  background: transparent;
}

.vertical-menu [data-simplebar] {
  z-index: 9;
}


/* // winter sidebar */
body[data-sidebar="winter"] .vertical-menu {
  background-image: linear-gradient(to right, #556ee6, #34c38f);
  background-repeat: repeat-x;
}

@media (max-width: 991.98px) {
  body[data-sidebar="winter"] .navbar-brand-box {
    background-image: linear-gradient(to right, #556ee6, #34c38f);
    background-repeat: repeat-x;
  }
}

body[data-sidebar="winter"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background-image: linear-gradient(to right, #556ee6, #34c38f);
  background-repeat: repeat-x;
}

body[data-sidebar="winter"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background-image: linear-gradient(to right, #556ee6, #34c38f);
  background-repeat: repeat-x;
}

body[data-sidebar="winter"][data-sidebar-size="small"] .menu-title {
  background-image: linear-gradient(to right, #556ee6, #34c38f);
}

/* // ladylip sidebar */
body[data-sidebar="ladylip"] .vertical-menu {
  background-image: linear-gradient(to right, #556ee6, #f46a6a);
  background-repeat: repeat-x;
}

@media (max-width: 991.98px) {
  body[data-sidebar="ladylip"] .navbar-brand-box {
    background-image: linear-gradient(to right, #556ee6, #f46a6a);
    background-repeat: repeat-x;
  }
}

body[data-sidebar="ladylip"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background-image: linear-gradient(to right, #556ee6, #f46a6a);
  background-repeat: repeat-x;
}

body[data-sidebar="ladylip"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background-image: linear-gradient(to right, #556ee6, #f46a6a);
  background-repeat: repeat-x;
}

body[data-sidebar="ladylip"][data-sidebar-size="small"] .menu-title {
  background-image: linear-gradient(to right, #556ee6, #f46a6a);
}


/* // plumplate sidebar */
body[data-sidebar="plumplate"] .vertical-menu {
  background-image: linear-gradient(to right, #556ee6, #f1b44c);
  background-repeat: repeat-x;
}

@media (max-width: 991.98px) {
  body[data-sidebar="plumplate"] .navbar-brand-box {
    background-image: linear-gradient(to right, #556ee6, #f1b44c);
    background-repeat: repeat-x;
  }
}

body[data-sidebar="plumplate"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background-image: linear-gradient(to right, #556ee6, #f1b44c);
  background-repeat: repeat-x;
}

body[data-sidebar="plumplate"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background-image: linear-gradient(to right, #556ee6, #f1b44c);
  background-repeat: repeat-x;
}

body[data-sidebar="plumplate"][data-sidebar-size="small"] .menu-title {
  background-image: linear-gradient(to right, #556ee6, #f1b44c);
}

/* // strongbliss sidebar */
body[data-sidebar="strongbliss"] .vertical-menu {
  background-image: linear-gradient(to right, #f46a6a, #f1b44c);
  background-repeat: repeat-x;
}

@media (max-width: 991.98px) {
  body[data-sidebar="strongbliss"] .navbar-brand-box {
    background-image: linear-gradient(to right, #f46a6a, #f1b44c);
    background-repeat: repeat-x;
  }
}

body[data-sidebar="strongbliss"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background-image: linear-gradient(to right, #f46a6a, #f1b44c);
  background-repeat: repeat-x;
}

body[data-sidebar="strongbliss"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background-image: linear-gradient(to right, #f46a6a, #f1b44c);
  background-repeat: repeat-x;
}

body[data-sidebar="strongbliss"][data-sidebar-size="small"] .menu-title {
  background-image: linear-gradient(to right, #f46a6a, #f1b44c);
}

/* //greatwhale */
body[data-sidebar="greatwhale"] .vertical-menu {
  background-repeat: repeat-x;
}

@media (max-width: 991.98px) {
  body[data-sidebar="greatwhale"] .navbar-brand-box {
    background-image: linear-gradient(to right, #556ee6, #50a5f1);
    background-repeat: repeat-x;
  }
}

body[data-sidebar="greatwhale"].vertical-collpsed .vertical-menu .navbar-brand-box {
  background-repeat: repeat-x;
}

body[data-sidebar="greatwhale"].vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background-repeat: repeat-x;
}

body[data-sidebar="greatwhale"][data-sidebar-size="small"] .menu-title {
  background-image: linear-gradient(to right, #556ee6, #50a5f1);
}

.themesideimage {
  height: 80px;
  width: 40px;
}

body[data-sidebar-image] .vertical-menu:before {
  opacity: 0.33;
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

body[data-sidebar-image] .vertical-menu:after {
  opacity: 0.77;
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

body[data-sidebar-image] .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
  opacity: 0.07;
}

.vertical-content {
  writing-mode: vertical-rl;
  text-orientation: upright;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark) !important;
}

.wh-30 {
  width: 30px;
  height: 30px;
}

.gradient-colored {
  background-color: var(--primary);
}

.gradient-winter {
  background-image: linear-gradient(to right, #556ee6, #34c38f);
  background-repeat: repeat-x;
}

.gradient-lady-lip {
  background-image: linear-gradient(to right, #556ee6, #f46a6a);
  background-repeat: repeat-x;
}

.gradient-plum-plate {
  background-image: linear-gradient(to right, #556ee6, #f1b44c);
}

.gradient-strong-bliss {
  background-image: linear-gradient(to right, #f46a6a, #f1b44c);
}


/* // < < ===================================== Tost Component css < ===================================== > */
.toast {
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
  text-align: left;
  flex-basis: inherit !important;
  width: inherit !important;
  min-width: 450px;
  padding: 12px;
  min-height: 45px;
  border-radius: 4px !important;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.08);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.08);
}

.toast.success-txt .tost-center,
.toast.error-txt .tost-center,
.toast.warning-txt .tost-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toast.success-txt .tost-center .Tost_Content,
.toast.error-txt .tost-center .Tost_Content,
.toast.warning-txt .tost-center .Tost_Content {
  display: flex;
  align-items: center;
}

.toast.success-txt .tost-center .txtcontain,
.toast.error-txt .tost-center .txtcontain,
.toast.warning-txt .tost-center .txtcontain {
  margin: 0;
  font-size: var(--FONT14);
}

/* .toast.success-txt .tost-center .txtcontain strong, */
.toast.error-txt .tost-center .txtcontain strong,
.toast.warning-txt .tost-center .txtcontain strong {
  font-weight: var(--font-weight-semibold);
}

.toast.success-txt .tost-center i,
.toast.error-txt .tost-center i,
.toast.warning-txt .tost-center i {
  margin-right: 12px;
  font-size: var(--FONT16);
  line-height: 16px;
  color: #212529;
}

.toast.success-txt .tost-center .mdi-close,
.toast.error-txt .tost-center .mdi-close,
.toast.warning-txt .tost-center .mdi-close {
  margin-right: 0px;
}

.toast.success-txt .tost-center .tostMassage,
.toast.error-txt .tost-center .tostMassage,
.toast.warning-txt .tost-center .tostMassage {
  margin-right: 12px;
}

.toast.success-txt {
  background: #d6f3e9;
  border: #d6f3e9;
}

.toast.success-txt .tost-center i {
  color: #1f7556;
}

.toast.success-txt .tost-center .txtcontain {
  color: #1f7556;
  font-weight: var(--font-weight-normal);
}

.toast.error-txt {
  background: var(--toast-error-bg);
  border: 1px solid var(--toast-error-bg);
}

.toast.error-txt .tost-center i {
  color: var(--Warning);
}

.toast.error-txt .tost-center .txtcontain {
  color: var(--Warning);
  font-weight: var(--font-weight-normal);
}

.toast.warning-txt {
  background: #cbe4fb;
  border: 1px solid #cbe4fb;
}

.toast.warning-txt .tost-center i {
  color: #306391;
}

.toast.warning-txt .tost-center .txtcontain {
  color: #306391;
  font-weight: var(--font-weight-normal);
}

.toast.show {
  display: block;
}

/* // < < ===================================== End Tost Component css < ===================================== > */


/* // < < ===================================== page-head Logo Component css < ===================================== > */
.page-title-box {
  padding-bottom: 24px;
}

.page-title-box .breadcrumb {
  background-color: transparent;
  padding: 0;
}

.card {
  background-color: var(--pink-light-bg) !important;
  border-color: var(--pink-light-bg) !important;
}

.page-title-box h4 {
  text-transform: uppercase;
  font-weight: var(--font-weight-semibold);
  font-size: var(--FONT16) !important;
  color: var(--white);
}

.page-title-box .back-button {
  color: var(--dark-gray);
  font-weight: 700;
  font-size: var(--FONT16);
}

.page-title-box .back-button span {
  margin-left: 10px;
}

/* // < ===================================== End page-head Logo Component css ===================================== > */


/* // < ===================================== theme.scss ===================================== > */
body {
  overflow-y: auto;
  background-color: var(--pink-bg) !important;
}

body * {
  outline: none;
}

#sidebar-menu ul li a i {
  min-width: 2rem;
}

.leaflet-pane {
  z-index: 1;
}

svg>rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid var(--gray-100);
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

/* // kanban board */
.react-kanban-board {
  display: inherit !important;
}

.react-kanban-board .react-kanban-column {
  background-color: var(--white);
  padding: 1.25rem;
}

.react-kanban-board .react-kanban-column [data-rbd-draggable-context-id]>div {
  display: block !important;
}

.react-kanban-board>div {
  display: flex;
  justify-content: center;
  gap: 25px;
}

@media (max-width: 991.98px) {
  .react-kanban-board>div {
    flex-direction: column;
  }

  .react-kanban-board>div .react-kanban-column {
    width: 100%;
  }
}

.react-kanban-column {
  width: 33.33%;
}

.react-kanban-column>div {
  display: block !important;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: var(--white) !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid var(--border-color);
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: var(--input-color) !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: var(--primary) !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: var(--primary) !important;
}

.flatpickr-weekdays {
  background: var(--primary) !important;
}

span.flatpickr-weekday {
  background: var(--primary) !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: var(--primary) !important;
}

span.flatpickr-weekday {
  color: var(--white) !important;
}

.flatpickr-day.selected {
  background: var(--primary) !important;
}

.bs-select select {
  width: auto !important;
}

/* //File-manager */
.file-manager .table td {
  padding: 0.35rem;
}

/* //Authenication carousel */
.slider_css .slide {
  background: none !important;
}

.slider_css .carousel-status,
.slider_css .control-next,
.slider_css .control-prev {
  display: none !important;
}

.carousel .control-dots {
  margin: -5px 0px;
}

.carousel .dot {
  background: #556ee6 !important;
  width: 9px !important;
  height: 9px !important;
}

/* //Two verification Page */
.verification input:focus {
  border: none;
  outline: none !important;
}

@media (max-width: 768px) {
  .verification input {
    width: 40px !important;
  }
}

@media (max-width: 320px) {
  .verification input {
    width: 30px !important;
    height: 30px !important;
    font-size: 15px !important;
  }
}


/* //Two verification Page - 2 */
.verification-2 input:focus {
  border: none;
  outline: none !important;
}

@media (max-width: 768px) {
  .verification-2 input {
    width: 40px !important;
    font-size: var(--FONT14);
  }
}

@media (max-width: 320px) {
  .verification-2 input {
    width: 30px !important;
    height: 35px !important;
    font-size: 15px !important;
  }
}

/* //Calendar */
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: var(--FONT12) !important;
  margin-right: 2px;
}

/* //Chat Scrollbar */
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: var(--FONT10);
  padding: 1.35rem 1.25rem;
  /* background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  */
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
}

.close span {
  display: none;
}

/* // // Columns // */
.card-columns .card {
  margin-bottom: var(--grid-gutter-width);
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.1s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: var(--FONT14);
  transition: all 0.1s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

/* // React Table next css */
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

/* // datatable */
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a {
  display: block;
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}


/* //rightbar drawer */
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

/* //react-table */
.custom-header-css thead tr th div:last-child {
  min-height: 38px;
}

.custom-header-css thead td:nth-last-child(2) {
  width: 150px !important;
}

.editor_email .ck-content {
  height: 200px;
}

.jobs-categories .card-body a:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 991.98px) {
  .search-box {
    display: block !important;
  }

  .search-box .search-label {
    float: none;
    width: 100%;
  }

  .search-box .search-label .form-control {
    border-radius: 0.25rem !important;
  }
}

/* // select */
.css-1s2u09g-control,
.css-1pahdxg-control {
  background-color: var(--input-bg) !important;
  border-color: var(--input-border-color) !important;
}

.css-14el2xx-placeholder {
  color: var(--input-placeholder-color) !important;
}

.css-26l3qy-menu {
  box-shadow: none !important;
  border: 1px solid var(--input-border-color) !important;
}

.css-4ljt47-MenuList {
  /* background-color: var(--#{
   $prefix
 }
 custom-white) !important;
  */
  border-radius: 4px !important;
}

.css-319lph-ValueContainer .css-qc6sy-singleValue {
  color: var(--input-color) !important;
}

.select2-selection .css-b62m3t-container {
  color: var(--input-color) !important;
}

.css-1n7v3ny-option,
.css-9gakcf-option {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

/* // rdw-editor */
.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-editor-main {
  border-color: var(--input-border-color) !important;
  /* background-color: var(--#{
   $prefix
 }
 custom-white) !important;
  */
}

.rdw-option-wrapper {
  border: none !important;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  background-color: var(--input-bg) !important;
  border-color: var(--input-border-color) !important;
  box-shadow: none !important;
}


[data-layout-mode="dark"] .rdw-option-wrapper img,
[data-layout-mode="dark"] .rdw-dropdown-selectedtext img {
  filter: invert(1) !important;
}

/* // ck editor */
.ck.ck-toolbar {
  border: 1px solid var(--input-border-color) !important;
}


.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--input-border-color) !important;
}


.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: var(--input-border-color) !important;
}

/* // Custome css */
.requierdLabel:after {
  content: "*";
  position: relative;
  font-size: var(--FONT12);
  color: var(--red);
  top: -4px;
  left: 1px;
}

.theme-color {
  color: var(--sky-blue);
}

.form-bottom-link {
  font-weight: var(--font-weight-normal);
  font-size: var(--FONT14);
  margin-left: 4px;
  font-family: var(--font-family-sans-serif);
}

.form-bottom-link :hover {
  color: var(--sky-blue);
}

.Custome_alert {
  background: #37d3ee 33 !important;
  border: 1px solid #61c454 33;
}

input.error {
  border-color: #F46A6A;
}

.errorMsg {
  color: var(--red);
  margin-top: 6px;
  font-size: var(--FONT12);
}

.form-control {
  width: 100%;
  border-radius: 4px !important;
  padding: 7.5px 12px 7.5px 12px;
  color: var(--white) !important;
}

.auth-pass-inputgroup .form-control {
  width: 100%;
  border-radius: 4px !important;
  color: var(--darkGrey) !important;
  border-color: var(--black-10);
}

input.form-control {
  border-color: var(--black-10);
  background-color: var(--white) !important;
  color: var(--darkGrey) !important;
  box-shadow: none !important;
  font-size: var(--FONT14);
  padding: 7px 12px;
}

input.form-control:focus {
  border-color: var(--black-10);
  font-size: var(--FONT14);
}

.form-control::placeholder {
  color: var(--black-25) !important;
}

.form-control:-webkit-input-placeholder {
  color: var(--black-25) !important;
}

.form-control:-o-input-placeholder {
  color: var(--black-25) !important;
}

.form-control::-ms-input-placeholder {
  color: var(--black-25) !important;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  /* border-color: var(--red) !important; */
  /* background-image: url("../images/worning.svg"); */
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  /* border-color: var(--red) !important;
  background-image: url("../images/worning.svg"); */
}

.form-control.regex-match-class {
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  /* background-image: url("../images/tick.svg"); */
  /* padding-right: calc(1.5em + 0.75rem); */
  border-color: var(--black-10) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--red) !important;
}

.errorMsg {
  & span {
    font-size: var(--FONT12);
  }
}

.auth-pass-inputgroup .form-control.is-invalid {
  background-position: right calc(0.375em + 2.8rem) center;
}

.auth-pass-inputgroup {
  position: relative;
}

.auth-pass-inputgroup .form-control {
  width: 100% !important;
  padding: 7px 12px;
}

.auth-pass-inputgroup .btn {
  position: absolute !important;
  right: 1px;
  z-index: 9 !important;
  border: none !important;
  top: 1px;
  padding-top: 5px;
  padding-bottom: 4px;
  /* background-color: var(--white-border) !important; */
  padding-left: 10px;
  padding-right: 10px;
  height: 36px;
}

.auth-pass-inputgroup.validPass .form-control {
  border-color: var(--black-10);
  padding-right: 44px;
}

.auth-pass-inputgroup .btn i {
  font-size: var(--FONT16);
  color: var(--black);
}

.auth-pass-inputgroup .iconInputRightStyle {
  position: absolute;
  right: 12px;
  z-index: 9;
  top: 1px;
  display: flex;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 8px;
}

.auth-pass-inputgroup .iconInputRightStyle i {
  font-size: var(--FONT16);
  color: var(--TextColorDark);
  line-height: 16px;
}

.auth-pass-inputgroup #password-addon {
  overflow: hidden;
  /* border: 1px solid var(--black-10); */
  border-radius: 0px 0.25rem 0.25rem 0px;
  background-color: var(--gray) !important;
  /* border-color: var(--black-10) !important; */
  height: 35px;
  border-left: 1px solid var(--black-10) !important;
}

/* .vertical-menu {
  transition: 0.5s all;
} */

.vertical-collpsed .main-content {
  margin-left: 70px;
}

.burger-menu {
  display: none;
  border: 1px solid var(--PrimaryBlue) !important;
}

.burger-menu i {
  position: relative;
  top: 1px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .burger-menu {
    display: block;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a .left-menu {
    /* background-color: transparent; */
  }

  #sidebar-menu ul li a .left-menu svg {
    margin: 0px !important;
  }

  .vertical-collpsed.sidebar-enable ul {
    padding: 0px 10px;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a .left-menu {
    flex-direction: row;
    /* min-height: 43px; */
    align-items: center;
    justify-content: flex-start;
    margin: unset;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a .left-menu span {
    /* display: none; */
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a .left-menu svg {
    /* min-width: 50px; */
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
    min-height: 43px;
    /* max-height: 43px; */
    display: flex;
    align-items: center;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
    min-height: 43px;
    /* max-height: 43px; */
    display: flex;
    align-items: center;
  }

  .vertical-collpsed.sidebar-enable .main-content {
    margin-left: 00px !important;
  }
}

html {
  overflow: hidden;
}

/* Shimer Place Holder */
.Shimer {
  background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
  animation: placeholderShimmer 2s linear 0s infinite normal forwards;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

.emptyRow {
  display: block !important;
}

.emptyRow td {
  width: 100%;
  display: block !important;
}

.emptyRow td .cricle-image {
  margin-bottom: 16px;
}

.emptyRow td .cricle-image img {
  filter: grayscale(100%);
}

.emptyRow td .no-data-text {
  width: 100%;
  display: block;
  font-size: var(--FONT16);
  line-height: 24px;
  font-weight: var(--Font-weight-600);
  color: var(--black);
  margin-bottom: 4px;
}

.emptyRow td .no-desc {
  width: 100%;
  max-width: 400px;
  font-size: var(--FONT14);
  line-height: 20px;
  font-weight: var(--Font-weight-400);
  color: var(--black-50);
}

.back-row-style {
  display: flex;
  align-self: flex-start;
  padding-bottom: 24px;
}

.back-button-style {
  background-color: transparent;
  padding: 0px;
  border: none;
  display: flex;
  align-items: center;
  font-size: var(--FONT16);
  color: var(--black);
  font-weight: var(--Font-weight-600);
}

.back-row-style .back-button-style i {
  font-size: var(--FONT24);
  margin-right: 10px;
  color: var(--black);
  font-weight: var(--Font-weight-400);
}


.custom-modal .modal-dialog {
  max-width: 491px;
}

.custom-modal .modal-dialog .modal-content {
  border-radius: 4px;
}

.custom-modal .modal-dialog .modal-content .cancel-btn {
  color: var(--black-50);
  font-weight: var(--Font-weight-600);
  background-color: var(--white);
}

.custom-modal .title-icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--black-10);
}

.custom-modal .title-icon-wrapper .modal-title h4 {
  font-size: var(--FONT24);
  font-weight: var(--Font-weight-600);
}

.custom-modal .modal-dialog .modal-content .modal-body {
  padding: 20px 20px;
}

.td-wrapper .checkbox-main .form-check-input {
  width: 16px;
  height: 16px;
  border: 1px solid var(--black-10) !important;
  cursor: pointer;
}
.td-wrapper .checkbox-main .form-check-input:focus{
  box-shadow: none;
}
.td-wrapper .checkbox-main .form-check-input:checked {
  background-color: var(--black) !important;
}

.td-wrapper .checkbox-main .form-check-input.uncheck:checked {
  background-color: transparent !important;
}

.form-check-input.uncheck:checked[type=checkbox] {
  background-image: none;
}

.grayBadge {
  background-color: var(--Gray-badge-bg);
  color: var(--Gray-badge-color);
  padding: 2px 10px;
  border-radius: 50px;
}

.greenBadge {
  background-color: var(--Green-badge-bg);
  color: var(--Green-badge-color);
  padding: 2px 10px;
  border-radius: 50px;
}

.orangeBadge {
  background-color: var(--Orange-badge-bg);
  color: var(--Orange-badge-color);
  padding: 2px 10px;
  border-radius: 50px;
}

@media (max-width: 1440px) {
  .vertical-menu {
    width: 250px;
  }
}

@media (max-width: 1280px) {
  .main-content {
    margin-left: 250px;
  }

  .page-content {
    padding: calc(var(--header-height) + var(--grid-gutter-width)) 20px 0px 20px;
  }

  .main-content .mainCard .main_card_body {
    padding: 10px !important;
  }
}

@media (max-width: 1280px) and (orientation:landscape) {
  .auth-pass-inputgroup #password-addon {
    height: 36px;
  }
}

@media (max-width: 1024px) {
  .pink-btn {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .custom-btn:disabled {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .custom-btn {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .heading {
    font-size: var(--FONT14) !important;
    line-height: 21px !important;
    padding-bottom: 20px !important;
  }

  .form-label {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  input.form-control {
    font-size: var(--FONT12) !important;
    line-height: 18px !important;
  }

  .back-button-style {
    font-size: var(--FONT14) !important;
  }

  .back-row-style .back-button-style i {
    font-size: var(--FONT20) !important;
  }

  .auth-pass-inputgroup #password-addon {
    height: 32px;
    border-bottom: 1px solid;
  }

  .auth-pass-inputgroup #password-addon {
    height: 32px;
  }
}

@media (max-width: 810px) {
  .auth-pass-inputgroup #password-addon {
    height: 32px;
    border-bottom: 1px solid;
  }
}

@media (max-width: 810px) and (orientation:portrait) {
  .auth-pass-inputgroup #password-addon {
    height: 34px;
    top: 1px;
  }
}



.customeSelectWrapper .multiSelecter .react-select__control {
  height: 38px;
  min-height: 38px;
  box-shadow: none;
  border-color: var(--black-10) !important;
}

.customeSelectWrapper .multiSelecter .react-select__control:hover {
  border: 1px solid var(--black-10);
}


.customeSelectWrapper .multiSelecter .react-select__control .react-select__value-container {
  padding: 0px 8px;
  flex-wrap: nowrap;
}


.customeSelectWrapper .multiSelecter .react-select__control .react-select__value-container .react-select__placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--black);
  margin: 0px;
  text-transform: capitalize;
}


.customeSelectWrapper .multiSelecter .react-select__control .react-select__input-container {
  margin: 0px;
  padding: 0px;
}


.customeSelectWrapper .multiSelecter .react-select__single-value {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--black);
  margin: 0px;
  padding: 0;
}


.customeSelectWrapper .multiSelecter .react-select__indicators {
  position: unset !important;
  cursor: pointer;
}


.customeSelectWrapper .multiSelecter .react-select__indicators .react-select__clear-indicator {
  position: unset !important;
  display: flex;
  align-items: center;
  max-height: 34px;
  padding: 0px;
}


.customeSelectWrapper .multiSelecter .react-select__indicators .react-select__clear-indicator:before {
  content: "\F0156";
  font-family: Material Design Icons;
  font-size: var(--FONT14);
  color: var(--gray-500) !important;
}


.customeSelectWrapper .multiSelecter .react-select__indicators .react-select__clear-indicator svg {
  width: 0px;
}


.customeSelectWrapper .multiSelecter .react-select__indicator-separator {
  display: none;
}


.customeSelectWrapper .react-select__control--is-focused {
  border: 1px solid var(--black-10) !important;
}


.customeSelectWrapper .multiSelecter .react-select__menu .react-select__option {
  display: flex;
  white-space: pre;
  flex-direction: row;
  flex-wrap: unset;
  background-color: var(--white);
  border-radius: 4px;
  color: var(--black) !important;
  font-size: var(--FONT14) !important;
  font-weight: 400 !important;
  line-height: 19px;
  align-items: center;
  cursor: pointer;
  white-space: break-spaces;
  word-break: normal;
  padding: 4px 4px !important;
  margin: 4px 0px !important;
}

.customeSelectWrapper .react-select__option--is-selected {
  background-color: rgba(236, 240, 246, 1) !important;
}

.customeSelectWrapper .multiSelecter .react-select__indicator svg {
  width: 16px;
}

.customeSelectWrapper .multiSelecter .react-select__indicator svg path {
  fill: var(--black);
}

.customeSelectWrapper .multiSelecter .react-select__menu .react-select__option input[type="checkbox"]~label span::before {
  content: "\F012E";
  font: normal normal normal 24px/1 Material Design Icons;
  position: absolute;
  z-index: 1111;
  visibility: visible;
  color: var(--grey);
}


.customeSelectWrapper .multiSelecter .react-select__menu .react-select__option input[type="checkbox"]:checked~label {
  transform: scale(1);
  visibility: hidden;
  position: relative;
}


.customeSelectWrapper.multiSelecter .react-select__menu .react-select__option input[type="checkbox"]:checked~label span::before {
  opacity: 1;
  position: absolute;
  z-index: 1111;
  visibility: visible;
  content: "\F0132";
  font: normal normal normal 24px/1 Material Design Icons;
  top: 0;
  left: 0;
  color: var(--PrimaryBlue);
}


.css-1nmdiq5-menu {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: var(--prefix-heading-dark-bg);
}


.react-select__menu-list {
  padding: 0 !important;
}


.multiSelecter .react-select__menu-list {
  padding: 8px 8px 8px 8px !important;
  position: relative;
  max-height: 140px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  padding-right: calc(1.5em + 3.5rem) !important;
}
.react-datepicker-popper{
  z-index: 2;
}
.reactEasyCrop_CropArea {
  /* width: 95% !important;
  height: 95% !important; */
}
.img-upload .close-icon{
  background-image: url('../images/white-wrong.svg');
  position: absolute;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;

  background-color: var(--ERROR);
  padding: 8px;
  border-radius: 50px;
  background-size: 9px;
  opacity: 1;
  right: -7px;
  top: -7px;
  border: none;
  background-position: 3px 3px;
  
}